import { Pipe, PipeTransform } from '@angular/core';
import { CommonBindingDataService } from '../services/common-binding-data.service';
@Pipe({
  name: 'timeZoneFormatePipe'
})
export class TimeZoneFormatePipe implements PipeTransform {
  constructor(private commonBindingDataService: CommonBindingDataService) {

  }

  transform(value: any, args?: any): string {
    return this.commonBindingDataService.toTimezoneAbbr(new Date(), value);
  }

}
