import { Injectable } from '@angular/core';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageOpenShiftsService {
  private publichOpenshiftUpdate = new BehaviorSubject<boolean>(false);
  private rowSelectedData = new Subject<any>()
  private isDialogOpen = new BehaviorSubject<boolean>(false);
  constructor(private restApiService: RestApiService) { }


  setDialogStatus(value: boolean) {
    this.isDialogOpen.next(value);
  }

  getDialogStatus() {
    return this.isDialogOpen.asObservable();
  }

  setData(value: any) {
    this.rowSelectedData.next(value);
  }

  getData() {
    return this.rowSelectedData.asObservable();
  }
  setValue(value: boolean) {
    this.publichOpenshiftUpdate.next(value);
  }

  getValue() {
    return this.publichOpenshiftUpdate.asObservable();
  }

  publishOpenshift(data): Observable<any> {
    return this.restApiService.post('', '/secure/open-shifts', data, 'page-center');
  }

  getTeam(providerIds?): Observable<any> {
    const providerId = providerIds ? 'providerIds=' + providerIds : 'providerIds=';
    return this.restApiService.get('', '/secure/teams/data/all?' + providerId, 'page-center');
  }

  getOpenShiftDetails(filterData): Observable<any> {
    return this.restApiService.get('', '/secure/open-shifts/list/data?date=' + filterData);
  }

  getMyOpenShiftDetails(filterData, userId): Observable<any> {
    return this.restApiService.get('', '/secure/open-shifts/' + userId + '/list/data?date=' + filterData);
  }

  getMemberListWithDateDetails(teamId, date): Observable<any> {
    return this.restApiService.get('', `/secure/teams/${teamId}/team-members` + '?date=' + date, 'page-center');
  }

  getOpenShiftPopDetails(openShiftId): Observable<any> {
    return this.restApiService.get('', '/secure/open-shifts/' + openShiftId);
  }
  retractBroadcast(openShiftId): Observable<any> {
    return this.restApiService.put('', `/secure/open-shifts/${openShiftId}/retract-request`, 'page-center');
  }
  approveReject(id, data): Observable<any> {
    return this.restApiService.put('', `/secure/open-shift-members/${id}/status`, data, 'page-center');
  }
  addShiftPlanning(data): Observable<any> {
    return this.restApiService.post('', '/secure/shift-plan', data, 'page-center');
  }

  getShiftPlanningData(limit, offset): Observable<any> {
    return this.restApiService.get('', '/secure/shift-plan/list/data?limit=' + limit + '&offset=' + offset, 'page-center');
  }

  updateShiftPlanning(id, data): Observable<any> {
    return this.restApiService.put('', `/secure/shift-plan/${id}`, data, 'page-center');
  }

  deleteShiftPlanning(id, data): Observable<any> {
    return this.restApiService.put('', `/secure/shift-plan/${id}/delete`, data, 'page-center');
  }

  applyDenyLeave(id, userDetails): Observable<any> {
    return this.restApiService.put('', `/secure/open-shift-members/${id}/apply-deny`, userDetails);
  }

  // Mange shift Team Services

  getTeamList(fromDate, toDate, limit, offset, searchText, statusIds, sortType, viewType): Observable<any> {
    return this.restApiService.get('', `/secure/team-shifts` + '?fromDate=' + fromDate + '&toDate=' + toDate +
      '&limit=' + limit + '&offset=' + offset + '&searchText=' + searchText + '&statusIds=' + statusIds +
      '&viewType=' + viewType + '&sortColumn=teamName&sortType=' + sortType);
  }


  getSchedulesUserList(driverId, date?): Observable<any> {
    return this.restApiService.get('', `/secure/users-schedules/${driverId}?date=${date}`, 'page-center');
  }

  updateDriverSchedule(driverId, data): Observable<any> {
    return this.restApiService.post('', `/secure/user-schedules/multi/${driverId}`, data, 'page-center');
  }

  deleteDriverSchedule(driverId, filterDate): Observable<any> {
    return this.restApiService.delete('', `/secure/user-schedules/${driverId}?date=${filterDate}`, 'page-center');
  }

  // Mange shift Team Member Services

  getTeamMemberList(fromDate, toDate, limit, offset, searchText, statusIds, userType, userRoleIds, sortType, viewType): Observable<any> {
    return this.restApiService.get('', `/secure/team-members-shifts` + '?fromDate=' + fromDate + '&toDate=' + toDate +
      '&limit=' + limit + '&offset=' + offset + '&searchText=' + searchText + '&statusIds=' + statusIds + '&userTypeIds=' + userType + '&userRoleIds=' + userRoleIds
      + '&viewType=' + viewType + '&sortColumn=firstName&sortType=' + sortType);
  }

  cancelMemberLeave(id): Observable<any> {
    return this.restApiService.delete('', `/secure/users/leave/${id}/cancel`);
  }

  applyLeave(data): Observable<any> {
    return this.restApiService.post('', `/secure/users/leave/apply`, data);
  }

  importExcelScheduleProviderV2(data): Observable<any> {
    return this.restApiService.post('', '/secure/v2/users-schedules/import/xls', data, 'page-center');
  }

  // remove after implementing  v2 for import module
  importExcelScheduleProvider(data): Observable<any> {
    return this.restApiService.post('', '/secure/users-schedules/import/xls', data, 'page-center');
  }

  // helper service

  convertToCssClass(className: string) {
    if (!className) {
      return '';
    }
    const lowerCaseClass = className.split(' ').map((name) => name.toLowerCase());
    return lowerCaseClass.join('-');
  }

  getProvider(): Observable<any> {
    return this.restApiService.get('', '/secure/providers/data/all', 'page-center');
  }

  downloadExcelData(url: string) {
    return this.restApiService.excel('', url, 'export.xls', 'page-center');
  }

  exportReportXLS(url): Observable<any> {
    return this.restApiService.post('', url, 'page-center');
  }

  getScheduleBulkUsers(): Observable<any> {
    return this.restApiService.get('', '/secure/schedule/bulk/users', 'page-center');
  }

  getUsers(): Observable<any> {
    return this.restApiService.get('', '/secure/shift-plan/web/users', 'page-center');
  }

  addScheduleBulk(data): Observable<any> {
    return this.restApiService.post('', '/secure/schedule/bulk', data, 'page-center');
  }

  teamMemberSaveFilter(data): Observable<any> {
    return this.restApiService.post('', '/secure/user-filters', data, 'page-center');
  }

  getLoginUserRoles(): Observable<any> {
    return this.restApiService.get('', '/secure/user-roles', 'page-center');
  }

  resetFilter(userId, type): Observable<any> {
    return this.restApiService.delete('', '/secure/user-filters/' + userId + '?filterType=' + type, 'page-center');
  }

  teamGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  teamMemberGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  shiftPlanGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }

  manageShiftGetFilter(userID, type): Observable<any> {
    return this.restApiService.get('', '/secure/user-filters/' + userID + '?filterType=' + type, 'page-center');
  }
}
