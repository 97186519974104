<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img class="company-logo-background" src='./assets/images/temp/rainbowcare_logo.png'>
          </div>
          <div class="form-field ui-g-12 switch-btn" style="text-align: center;" translate>Password set successfully.
          </div>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12 app-link">
          <div class="app-link-web"><a href="./#/signin">Click here</a> to login to the Web Portal.</div>
          <div class="app-link-mobile">If you are a Mobile Crisis Team user, please login from the Mobile App.</div>
        </div>
        <div class="remove-float"></div>
      </div>
    </div>
  </div>
</div>