import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';

@Component({
  selector: 'app-sign-in-error',
  templateUrl: './sign-in-error.component.html',
  styleUrls: ['./sign-in-error.component.scss']
})
export class SignInErrorComponent implements OnInit {

  saml = '';
  signature = '';
  signInResponse: any;
  notification_token;
  device_details;
  signInLabel: any;
  constructor(
    private userCommonService: UserCommonService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService,

  ) {
    // this.saml = this.route.snapshot.queryParams['saml']; this.signature = this.route.snapshot.queryParams['signature'];
    // if(this.saml === 'success') {
    //   // this.UserSignInSSOOkta(this.signature);
    // }else{
    //  this.uiService.showMessage(this.commonBindingDataService.getLabel('invalid_user_creditionals'), AppSettings.MESSAGE_TYPES.ERROR);
    //   this.router.navigate(['/signin-sso']);
    // }
   }
   // getting queryParams in route api
  //  queryParams: Object { err: "invalid-user" }
  //  ​​
  //  err: "invalid-user"
  //  ​​
  //  <prototype>: Object { … }
  //  ​
  //  routeConfig: Object { path: "saml2-login-error",
  ngOnInit(): void {
    this.saml = this.route.snapshot.queryParams['saml']; this.signature = this.route.snapshot.queryParams['signature'];
    if (this.saml === 'success') {
       // this.UserSignInSSOOkta(this.signature);
     } else {
      //  setTimeout(()=> {
      //    //this.uiService.showMessage(this.commonBindingDataService.getLabel('invalid_user_creditionals'),
      // AppSettings.MESSAGE_TYPES.ERROR);
      //  },5000)


     }
  }

  backToLogin() {
    this.router.navigate(['/signin-sso']);

  }

}
