<div class="sdoh-container" *ngIf="!isFormAlreadySubmitted && !isFormSubmitted && pageQuestions?.userSdohScreeningCategorySubCategories?.length>0">
    <div class="ui-g page-header-c">
        <div class="ui-g-12 ui-md-12 ui-lg-12" style="text-align: center;">
        <h1 class="page-title"><span class="backbtn"></span>SDoH Screening Form</h1>
        </div>
    </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12">
    <div class="ui-g page-header-c category-header">
        <div class="ui-g-12 ui-md-12 ui-lg-12">
            {{ pageQuestions?.categoryName }}
        </div>
    </div>
    <div *ngFor="let subcat of pageQuestions?.userSdohScreeningCategorySubCategories; let i =index">
    <div class="card" *ngFor="let que of subcat.subCategoryQuestions; let j =index">
      <div *ngIf="que.questionType=='2'" class="card-container">
        <div class="queshtion-lable">
          {{ que.question }}
        </div>
        <div class="options-lable" *ngFor="let opt of que.subCategoryQuestionOptions; let k =index" id="parent">
          <div id="wide">
            <p-checkbox class="vip-chk" name="subCat" binary="true"
            [(ngModel)]="opt.checked" [ngModelOptions]="{standalone: true}">
            </p-checkbox>
          </div>
          <div style="padding-left: 5px;" id="narrow"> {{ opt.option }} </div>
        </div>
      </div>
      <div *ngIf="que.questionType=='1'" class="card-container">
        <div class="queshtion-lable">
          {{ que.question }}
        </div>
        <div class="options-lable" *ngFor="let opt of que.subCategoryQuestionOptions; let k =index" id="parent">
          <div id="wide">
            <p-radioButton [name]="que.question" binary="true" [value]="true"
            [(ngModel)]="opt.checked" [ngModelOptions]="{standalone: true}"></p-radioButton>
          </div>
          <div style="padding-left: 5px;" id="narrow"> {{ opt.option }} </div>
        </div>
      </div>
      <div *ngIf="que.questionType=='3'" class="card-container">
        <div class="queshtion-lable">
          {{ que.question }}
        </div>
        <div class="options-lable" *ngFor="let opt of que.subCategoryQuestionOptions; let k =index" id="parent">
          <div id="wide">
            <p-radioButton [name]="que.question" binary="true" [value]="true"
            [(ngModel)]="opt.checked" [ngModelOptions]="{standalone: true}"></p-radioButton>
          </div>
          <div style="padding-left: 5px;" id="narrow"> {{ opt.option }} </div>
        </div>
      </div>
    </div>
    </div>
    <!-- <div class="card">
      <div class="container">
        <div class="queshtion-lable">
          Test question Test question Test question Test question?
        </div>
        <div class="options-lable">
          <p-radioButton name="tripFor"></p-radioButton>
          <span> item </span>
        </div>
      </div>
    </div> -->
    <!-- <div class="card">
      <div class="container">
        <div class="queshtion-lable">
          Test question Test question Test question Test question?
        </div>
        <div class="options-lable">
          <p-checkbox class="vip-chk" name="subCat">
          </p-checkbox>
          <span> item </span>
        </div>
        <div class="options-lable">
          <p-checkbox class="vip-chk" name="subCat">
          </p-checkbox>
          <span> item </span>
        </div>
        <div class="options-lable">
          <p-checkbox class="vip-chk" name="subCat">
          </p-checkbox>
          <span> item </span>
        </div>
        <div class="options-lable">
          <p-checkbox class="vip-chk" name="subCat">
          </p-checkbox>
          <span> item </span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="container">
        <div>
          <h4><b>Test question Test question Test question?</b></h4>
        </div>
        <div style="margin-bottom: 1%;">
          <p-radioButton name="tripFor"></p-radioButton>
          <span> item </span>
        </div>
        <div style="margin-bottom: 1%;">
          <p-radioButton name="tripFor"></p-radioButton>
          <span> item </span>
        </div>
        <div style="margin-bottom: 1%;">
          <p-radioButton name="tripFor"></p-radioButton>
          <span> item </span>
        </div>
        <div style="margin-bottom: 1%;">
          <p-radioButton name="tripFor"></p-radioButton>
          <span> item </span>
        </div>
      </div>
    </div> -->
    
  </div>
  <div class="ui-g-12 ui-md-6 ui-lg-6">
    <button *ngIf="offset!=0" class="cancel-btn" pButton type="button" [label]="'BACK' | translate" (click)="onClickBack()"></button>&nbsp;
    <button *ngIf="offset!=categoryQuestions.length-1" pButton class="save-btn" type="button" [label]="'NEXT' | translate" (click)="onClickNext()"></button>
    <button *ngIf="offset==categoryQuestions.length-1" pButton class="save-btn" type="button" [label]="'SUBMIT' | translate" (click)="onClickSubmit()"></button>
  </div>
  <div class="ui-g-12 ui-md-3 ui-lg-3 pages-lable">
    <div>
      <span> Page {{ offset+1 }} of {{ categoryQuestions.length }} </span>
      <!-- <progress id="progressBar" max="100" value="22"></progress> -->
    </div>
  </div>
  <div class="ui-g-12 ui-md-1 ui-lg-1">
    <div>
      <progress id="progressBar" [max]="categoryQuestions.length" [value]="offset+1"></progress>
    </div>
  </div>

</div>

<div class="sdoh-container" *ngIf="isFormSubmitted">
  <div class="ui-g page-header-c">
      <div class="ui-g-12 ui-md-12 ui-lg-12" style="text-align: center;">
      <!-- <h1 class="page-title"><span class="backbtn"></span>SDoH Screening Form</h1> -->
      </div>
  </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-top: 8%;">
    <div class="card">
      <div class="card-container">
        <div class="submitted-lable">
          <h3>SDoH screening details submitted successfully!</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sdoh-container" *ngIf="isFormAlreadySubmitted">
  <div class="ui-g page-header">
      <div class="ui-g-12 ui-md-12 ui-lg-12" style="text-align: center;">
      <!-- <h1 class="page-title"><span class="backbtn"></span>SDoH Screening Form</h1> -->
      </div>
  </div>

  <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-top: 8%;">
    <div class="card">
      <div class="card-container">
        <div class="submitted-lable">
          <h3>SDoH screening form is already submitted.</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [blockScroll]="true" modal="true" [draggable]="false" position="center" autoResize="false" responsive="true"
  styleClass="assign-search" [(visible)]="openSuccessDialog" (onHide)="onClickOk()"
  [style]="{width: '550px'}">
  <p-header>
    <label translate>SDoH screening data submitted successfully!</label>
  </p-header>
  <div class="ui-g form-container">
    <div class="ui-g-12 ui-g-nopad form-buttons">
      <button class="cancel-btn" pButton type="button" label="OK" (click)="onClickOk()"></button>
      <!-- <button class="cancel-btn" pButton type="button" label="Cancel" (click)="uploadPopupCancel()"></button> -->
    </div>
  </div>
</p-dialog>
