<div class="ui-g form-container form-without-border">
    <form [formGroup]='chagePasswordForm'>
        <div>
            <div class="ui-g">
                <h2 class="popup-header">{{change_password}}</h2>
            </div>

            <div class="ui-g">
                <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
                    <div class="form-control-c">
                        <input autocomplete="off" appNoWhiteSpace pPassword type="password" class="bottom-border"
                            formControlName="oldPassword" [placeholder]="old_password" />
                    </div>
                    <div class="form-errors ui-g-12"
                        *ngIf="chagePasswordForm.controls.oldPassword.errors  && (chagePasswordForm.controls.oldPassword.dirty || chagePasswordForm.controls.oldPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.required" translate>
                            error_old_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.minlength" translate>
                            password_too_short
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>

                <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
                    <div class="form-control-c">
                        <input autocomplete="off" appNoWhiteSpace pPassword type="password" class="bottom-border"
                            formControlName="newPassword" [placeholder]="new_password" />
                    </div>

                    <div class="form-errors ui-g-12"
                        *ngIf="chagePasswordForm.controls.newPassword.errors  && (chagePasswordForm.controls.newPassword.dirty || chagePasswordForm.controls.newPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.required" translate>
                            error_new_password_required
                        </div>
                        <div [hidden]="(!chagePasswordForm.controls.newPassword.errors.minlength)
                        && (!chagePasswordForm.controls.newPassword.errors.pattern)" translate>
                            error_password_pattern
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>

                <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
                    <div class="form-control-c">
                        <input autocomplete="off" appNoWhiteSpace pPassword type="password" class="bottom-border"
                            formControlName="newConfirmPassword" [placeholder]="confirm_password" />
                    </div>
                    <div class="form-errors ui-g-12"
                        *ngIf="chagePasswordForm.controls.newConfirmPassword.errors  && (chagePasswordForm.controls.newConfirmPassword.dirty || chagePasswordForm.controls.newConfirmPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.required" translate>
                            error_new_confirm_password_required
                        </div>
                        <div [hidden]="(!chagePasswordForm.controls.newConfirmPassword.errors.minlength)
                        && (!chagePasswordForm.controls.newConfirmPassword.errors.pattern)" translate>
                            error_password_pattern
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui-g">
                <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
                    <div class="form-errors alert alert-danger" [hidden]='!changePasswordErrors[0]["general"]'>
                        <div *ngFor="let ErrorMessage of changePasswordErrors['general']">
                            {{ErrorMessage.message}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui-g-12 ui-g-nopad pb-20">
                <div class="form-buttons">
                    <button pButton type="button" class="save-btn" label="Save" [disabled]="!chagePasswordForm.valid"
                        (click)="doChangePassword()"></button>
                    <button class="cancel-btn" pButton type="button" label="Cancel" (click)="onCancel()"></button>
                </div>
            </div>
        </div>
    </form>
</div>
