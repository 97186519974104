import { UserDetailsModel } from './user-details-model';

export class InfoModel {

  id = '';
  memberId = '';
  createdAt = 0;
  updatedAt = 0;
  firstName = '';
  lastName = '';
  email = '';
  numCountryCode = '';
  phoneNum = '';
  profileImage = '';
  referrenceNumber = '';
  addressLine1 = '';
  addressLine2 = '';
  passengerRating = 0;
  driverRating = 0;
  countryCode = '';
  cityId = '';
  zipcode = '';
  dateOfBirth = '';
  birthDate = '';
  genderStr = '';
  city = '';
  state = '';

  constructor(option?: {
    id: string;
    createdAt: number;
    updatedAt: number;
    firstName: string;
    lastName: string;
    email: string;
    numCountryCode: string;
    phoneNum: string;
    profileImage: string;
    referrenceNumber: string;
    addressLine1: string;
    addressLine2: string;
    passengerRating: number;
    driverRating: number;
    countryCode: string;
    cityId: string;
    zipcode: string;

  }) {

    if (option) {
      this.id = option.id;
      this.createdAt = option.createdAt;
      this.updatedAt = option.updatedAt;
      this.firstName = option.firstName;
      this.lastName = option.lastName;
      this.email = option.email;
      this.numCountryCode = option.numCountryCode;
      this.phoneNum = option.phoneNum;
      this.profileImage = option.profileImage;
      this.referrenceNumber = option.referrenceNumber;
      this.addressLine1 = option.addressLine1;
      this.addressLine2 = option.addressLine2;
      this.passengerRating = option.passengerRating;
      this.driverRating = option.driverRating;
      this.countryCode = option.countryCode;
      this.cityId = option.cityId;
      this.zipcode = option.zipcode;
    }
  }
}
