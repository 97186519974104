import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInLayoutComponent } from './pages/sign-in-layout/sign-in-layout.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { ForgotPasswordComponent } from './modules/shared/components/forgot-password/forgot-password.component';
import { OtpVerifiyComponent } from './modules/shared/components/otp-verifiy/otp-verifiy.component';
import { SetPasswordComponent } from './modules/shared/components/set-password/set-password.component';
import { ComplaintComponent } from './pages/complaint/complaint.component';
import { PasswordSetWithOtpVerifyComponent } from './pages/password-set-with-otp-verify/password-set-with-otp-verify.component';
import { RedirectionPageComponent } from './pages/redirection-page/redirection-page.component';
import { SupportComponent } from './pages/support/support.component';
// import { ZoomNewCallComponent } from './modules/shared/components/zoom-new-call/zoom-new-call.component';
import { SdohPatientFormComponent } from './pages/sdoh-patient-form/sdoh-patient-form.component';
import { TwilioVideoComponent } from './pages/twilio-video/twilio-video.component';
import { SignInPageComponent } from './pages/sign-in-page/sign-in-page.component';
import { SsoLoginGuard } from './modules/shared/guards/sso-login.guard';
import { SsosignInPageComponent } from './pages/sso-sign-in-page/ssosign-in-page.component';
import { privacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { SignInErrorComponent } from './pages/sign-in-error/sign-in-error.component';
import { UserAccountDeletedComponent } from './modules/shared/components/user-account-deleted/user-account-deleted.component';
import { ReportDownloadAuthorizationComponent } from './pages/report-download-authorization/report-download-authorization.component';
import { MyOpenShiftsComponent } from './modules/manage-shifts/pages/my-open-shifts/my-open-shifts.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    // redirectTo: 'dashboard'
    redirectTo: 'signin'
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signin-sso',
  },
  {
    path: 'signin-sso',
    pathMatch: 'full',
    component: SsosignInPageComponent,
  },
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInLayoutComponent,
  },
  {
    path: 'saml2-login-success',
    pathMatch: 'full',
    component: SignInPageComponent
  },
  {
    path: 'saml2-login-error',
    pathMatch: 'full',
    component: SignInErrorComponent
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent
  },
  {
    path: 'account-verification',
    pathMatch: 'full',
    component: UserAccountDeletedComponent
  },
  {
    path: 'sdoh-patient-form',
    pathMatch: 'full',
    component: SdohPatientFormComponent
  },
  {
    path: 'twilio-video',
    pathMatch: 'full',
    component: TwilioVideoComponent
  },
  {
    path: 'support',
    pathMatch: 'full',
    component: SupportComponent
  },
  {
    path: 'otp-verify',
    pathMatch: 'full',
    component: OtpVerifiyComponent
  },
  {
    path: 'set-password',
    pathMatch: 'full',
    component: SetPasswordComponent
  },
  {
    path: 'redirect-page',
    pathMatch: 'full',
    component: RedirectionPageComponent
  },

  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: privacyPolicyComponent
  },
  {
    path: 'file/export/:id',
    pathMatch: 'full',
    canActivate: [AuthenticationValidationService],
    component: ReportDownloadAuthorizationComponent
  },

  {
    path: 'password-set',
    component: PasswordSetWithOtpVerifyComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
  },
  {
    path: 'patients',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/passengers/passengers.module').then(m => m.PassengersModule)
  },
  {
    path: 'providers',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/providers/providers.module').then(m => m.ProvidersModule)
  },
  {
    path: 'hc-providers',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/drivers/drivers.module').then(m => m.DriversModule)
  },
  {
    path: 'marketing',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/marketing/marketing.module').then(m => m.MarketingModule)
  },
  {
    path: 'app-settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/tenant-settings/tenant-settings.module').then(m => m.TenantSettingsModule)
  },
  {
    path: 'masters',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'user-management',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  {
    path: 'settings',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'reports',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'provider-availability',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/provider-availability/provider-availability.module').then(m => m.ProviderAvailabilityModule)
  },
  {
    path: 'team-history',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/team-history/team-history.module').then(m => m.TeamHistoryModule)
  },
  {
    path: 'dispatch-request',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/dispatch-request/dispatch-request.module').then(m => m.DispatchRequestModule)
   // loadChildren: () => import('./modules/contact-form/contact-form.module').then(m => m.ContactFormModule)

  },
  {
    path: 'contact-form',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/contact-form/contact-form.module').then(m => m.ContactFormModule)

  },
  {
    path: 'resources',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/masters/masters.module').then(m => m.MastersModule)
  },
  {
    path: 'followup',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/contact-form-followup/contact-form-followup.module').then(m => m.ContactFormFollowupModule)
  },
  {
    path: 'manage-shift',
    canActivate: [AuthenticationValidationService],
    loadChildren: () => import('./modules/manage-shifts/manage-shifts.module').then(m => m.ManageShiftsModule)
  },
  {
    path: 'open-shifts',
    pathMatch: 'full',
    canActivate: [AuthenticationValidationService],
    component: MyOpenShiftsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
