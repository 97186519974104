<div class="ui-g page-header-c">
  <div class="ui-g-12 ui-md-6 ui-lg-6">
    <h1 class="page-title">Privacy Policy</h1>
  </div>
</div>
<div class="containar-layout">
<div class="ui-g">
  <div class="ui-g-16 ui-lg-16 ui-md-16">
    <div class="ui-g">
      <div class="ui-g-6">
      </div>
      <!-- <div class="form-buttons ui-g-6 ui-g-nopad">
        <button appAccessProvider accessCode="APP_SETTINGS_PRIVACY_POLICY_UPDATE_PRIVACY_POLICY" pButton
          (click)="editPolicy()" type="button" icon="fa fa-pencil" [label]="labelEdit"></button>
      </div> -->
    </div>
    <p-card *ngIf="data " >
      <div [innerHTML]="data | keepHtml"></div>
    </p-card>
  </div>
</div>
</div>


