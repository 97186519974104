<div class="ui-g form-container">
  <form class="ui-g-12 ui-g-nopad" [formGroup]="bookingCancelform">
    <div class="form-field">
      <div class="form-control-c">
        <textarea formControlName="cancelReason" maxlength="200" [placeholder]="label_Cancelation" [rows]="5" [cols]="30" pInputTextarea
          autoResize="autoResize"></textarea>
      </div>
      <div class="form-errors"
        *ngIf="bookingCancelform.controls.cancelReason.errors  && (bookingCancelform.controls.cancelReason.dirty || bookingCancelform.controls.cancelReason.touched)">
        <div [hidden]="!bookingCancelform.controls.cancelReason.errors.required" translate>
          cancel_reason_req
        </div>
        <div [hidden]="!bookingCancelform.controls.cancelReason.errors.maxlength" translate>
          max_character_200
        </div>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
        <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
          {{ErrorMessage.message}}
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-g-nopad form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="labelCancel"
        (click)="cancel()"></button>
      <button pButton type="button" [label]="labelSave" icon="fa fa-check" [disabled]="!bookingCancelform.valid"
        (click)="cancelBooking()"></button>
    </div>
  </form>
</div>
