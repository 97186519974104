import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ErrorBindingService } from 'app/modules/shared/services/error-binding.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { NotificationsService } from 'app/modules/shared/services/notifications.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from './../../modules/shared/services/user-common.service';
// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html'
})

export class SignInPageComponent implements OnInit {
  saml = '';
  signature = '';
  signInResponse: any;
  notification_token;
  device_details;
  signInLabel: any;
  reportDownloadUrl: any;

  constructor(
    private userCommonService: UserCommonService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private notificationService: NotificationsService,
    private errorBindingService: ErrorBindingService,
    private uiService: UiService,
    private messagingService: MessagingService,

  ) {
    this.saml = this.route.snapshot.queryParams['saml']; this.signature = this.route.snapshot.queryParams['signature'];
  if (this.saml === 'success') {
    this.UserSignInSSOOkta(this.signature);
  }
  // else{
  //   this.uiService.showMessage(this.commonBindingDataService.getLabel('error_invalid_access_group'), AppSettings.MESSAGE_TYPES.ERROR);
  //   this.router.navigate(['/signin-sso']);
  // }
  }

  ngOnInit() {
    this.notificationService.canFetchNotification = false;
    this.storageService.setLocalStorage('isLogout', false);
    this.signInLabel = this.commonBindingDataService.getLabel('Login with SSO');
    this.reportDownloadUrl = localStorage.getItem('returnUrl');

    // this.route.queryParams.subscribe(params => {
    //   this.reportDownloadUrl = params.returnUrl;
    // });
  }
  doSignIn(signInData) {
    // window.location.href = 'https://harrisdms-dev-api.rainbow.health/api/rcdms003/saml2/ssologin/web';
    // this.UserSignInSSOOkta(signInData);
  }
 // ?authToken=${token}
  UserSignInSSOOkta(token: any) {
    this.notification_token = this.storageService.getItem('notificationToken');
    const ba = ['Chrome', 'Firefox', 'Safari', 'Opera', 'MSIE', 'Trident', 'Edge'];
    let b;
    const ua = navigator.userAgent;
    for (let i = 0; i < ba.length; i++) {
      if (ua.indexOf(ba[i]) > -1) {
        b = ba[i];
        break;
      }
    }
    if (b === 'MSIE' || b === 'Trident' || b === 'Edge') {
      b = 'Internet Explorer';
    }

    const temp = {
      deviceName: b,
      deviceType: 'web',
      os: navigator.platform,

      // appVersion : '1',
      // osVersion : '18',
      notificationToken: this.notification_token,
      // deviceId : 'AA2C210D-E050-4E5C-8CEC-424F3DAA9CF9Web'
    };
    this.device_details = {
      'authToken' : token,
      deviceDetails : temp
    };
    // signInData.deviceDetails = this.device_details;

  this.userCommonService.UserSignInSSO(this.device_details).subscribe(signInResult => {

    if (signInResult) {
      if (this.reportDownloadUrl ) {
        if (this.reportDownloadUrl.includes('?')) {
          const qParam = this.reportDownloadUrl.split('?')[1].split('&');
          const query = {};
          let newArry = [];
          newArry = qParam.map(item => item.split('=')).reduce((a, b) => a.concat(b));
          for (let i = 0; i < newArry.length; i++) {
            const s = newArry[i].replace(/['"]+/g, '');
            if (!(i % 2)) {
              query[newArry[i]] = newArry[i + 1];
            }
          }
          this.router.navigate([this.reportDownloadUrl.split('?')[0]], { queryParams: query });

        } else {
          this.router.navigate([this.reportDownloadUrl]);
        }
      } else {
        this.router.navigate(['/hc-providers']);
      }
      this.signInResponse = signInResult;
      sessionStorage.setItem('userRoleId',signInResult.user.roleId)
      sessionStorage.setItem('providerId',signInResult.user.id)
      this.storageService.setLocalStorage('userId', signInResult.user.id);

      if (signInResult.logoutToken) {
        sessionStorage.setItem('logoutToken', signInResult.logoutToken);
      }
      if (this.signInResponse.accessGroup !== null && this.signInResponse.accessGroup.length > 0) {
        this.notificationService.canFetchNotification = true;
        this.setSessionAndUserDetails(signInResult);
        this.userCommonService.getUserMasterMetaData();
        this.userCommonService.getAdminUserMetaData();
        this.userCommonService.getDispatchListFilter();
        this.userCommonService.getContactListFilter();
        // this.userCommonService.setRolesAndGenerateSidebar();
        const idleTime = Number(this.commonBindingDataService.getTenantConfigValue('session_timeout_in_mins')) ? Number(this.commonBindingDataService.getTenantConfigValue('session_timeout_in_mins')): AppSettings.IDLE_TIME;
        //this.storageService.setItem('idleTime', AppSettings.IDLE_TIME);
        this.storageService.setItem('idleTime', idleTime);
        this.storageService.setLocalStorage('idleTime', idleTime);
        this.storageService.setItemInCookies('sessionToken', signInResult.sessionToken);
        localStorage.setItem('saveAppointmentDraft', signInResult.saveAppointmentDraft);
        if (this.reportDownloadUrl ) {
          if (this.reportDownloadUrl.includes('?')) {
            const qParam = this.reportDownloadUrl.split('?')[1].split('&');
            const query = {};
            let newArry = [];
            newArry = qParam.map(item => item.split('=')).reduce((a, c) => a.concat(c));
            for (let i = 0; i < newArry.length; i++) {
              const s = newArry[i].replace(/['"]+/g, '');
              if (!(i % 2)) {
                query[newArry[i]] = newArry[i + 1];
              }
            }
            this.router.navigate([this.reportDownloadUrl.split('?')[0]], { queryParams: query });

          } else {
            this.router.navigate([this.reportDownloadUrl]);
          }
        } else {
          this.router.navigate(['/hc-providers']);
        }
      } else {
        this.uiService.showMessage(this.commonBindingDataService.getLabel('error_invalid_access_group'), AppSettings.MESSAGE_TYPES.ERROR);
      }
    }
  }, (error) => {
    //  this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
  });
  }

  setSessionAndUserDetails(results) {
    this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
    this.storageService.setItem(AppSettings.ACCESS_MENU, this.signInResponse.accessGroup);
    this.storageService.setItem('internalCheckListPermissions', results.internalCheckListPermissions);
    this.storageService.setItem('sdohScreeningPermissions', results.sdohScreeningPermissions);
    this.storageService.setItem('headerAccessPermissionDispatch', results.addDispatch);
    this.storageService.setItem('headerAccessPermissionContactForm', results.addContactForm);
    this.storageService.setItem('accessPermissionViewContactForm', results.viewContactForm);
    this.storageService.setItem('accessPermissionViewDispatchForm', results.viewDispatchForm);
    AppSettings.USER = results.user;
    this.setTagList();
    this.userCommonService.setRolesAndGenerateSidebar();
    if (results.user.userDetails !== null) {
      this.storageService.setItem(AppSettings.CURRENT_USER_CORPORATE_ID, results.user.userDetails.corporateId);
    }
  }

  setTagList() {
    this.userCommonService.getTagsListApi().subscribe(result => {
      this.storageService.setItem(AppSettings.TAG_DATA, result);
      localStorage.setItem('localTagdata', JSON.stringify(result));
    }, (error) => {
    });
  }
}
