<div class="ui-g form-container edit-profile form-without-border ">
  <form [formGroup]='editProfileForm'>
    <div class="ui-g">
      <h2 class="popup-title">{{ edit_profile }}</h2>
    </div>

    <div class="ui-g-12">
      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="profile-image">
          <img class="driver-pic" [src]="profileUrl" onerror="src='/assets/images/temp/default_profile.png'" />
        </div>
        <div class='display-image profile-btn'>
          <app-file-uploader (fileUpload)="onUploadProfileImage($event)" accept="image/*"
            [uploadButtonLabel]="chooseLabel"></app-file-uploader>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
        <div class="form-label">
          <label for="firstName" translate>f_name</label>
          <label class="asterisk">*</label>
        </div>
        <div class="form-control-c">
          <input autocomplete="off" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.substr(1)"
            formControlName="firstName" type="text" [placeholder]="first_name" pInputText />
        </div>
        <div class="form-errors ui-g-12"
          *ngIf="editProfileForm.controls.firstName.errors  && (editProfileForm.controls.firstName.dirty || editProfileForm.controls.firstName.touched)">
          <div [hidden]="!editProfileForm.controls.firstName.errors.required" translate>
            first_name_reqd
          </div>
          <div [hidden]="!editProfileForm.controls.firstName.errors.minlength" translate>
            min_length
          </div>
          <div [hidden]="!editProfileForm.controls.firstName.errors.maxlength" translate>
            max_length
          </div>
          <div [hidden]="!editProfileForm.controls.firstName.errors.pattern" translate>
            name_pattern
          </div>
        </div>
      </div>

      <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
        <div class="form-label">
          <label for="lastName" translate>l_name</label>
          <label class="asterisk">*</label>
        </div>
        <div class="form-control-c">
          <input autocomplete="off" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.substr(1)"
            formControlName="lastName" type="text" [placeholder]="last_name" pInputText />
        </div>
        <div class="form-errors ui-g-12"
          *ngIf="editProfileForm.controls.lastName.errors  && (editProfileForm.controls.lastName.dirty || editProfileForm.controls.lastName.touched)">
          <div [hidden]="!editProfileForm.controls.lastName.errors.required" translate>
            last_name_reqd
          </div>
          <div [hidden]="!editProfileForm.controls.lastName.errors.minlength" translate>
            min_length
          </div>
          <div [hidden]="!editProfileForm.controls.lastName.errors.maxlength" translate>
            max_length
          </div>
          <div [hidden]="!editProfileForm.controls.lastName.errors.pattern" translate>
            name_pattern
          </div>
        </div>
      </div>

      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label">
          <label for="email" translate>email</label>
          <label class="asterisk">*</label>
        </div>
        <div class="form-control-c">
          <input autocomplete="off" formControlName="email" maxlength="100" type="text" [placeholder]="email_id" disabled pInputText />
        </div>
        <div class="form-errors ui-g-12"
          *ngIf="editProfileForm.controls.email.errors  && (editProfileForm.controls.email.dirty || editProfileForm.controls.email.touched)">
          <div [hidden]="!editProfileForm.controls.email.errors.required" translate>
            error_email_id_required
          </div>
          <div [hidden]="!editProfileForm.controls.email.errors.pattern" translate>
            email_pattern
          </div>
        </div>
      </div>

      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label">
          <label for="phone" translate>phone</label>
          <!-- <label class="asterisk" *ngIf="isStaffUser">*</label> -->
        </div>
        <div class="">
          <div class="contact-with-countory">
            <div class="clearfix"></div>
            <div class="left">
              <p-dropdown autocomplete="off" [options]="countryCodeList" [(ngModel)]="countryCodeDefault" formControlName="phoneCode"
                [disabled]="true" [filter]="true"> </p-dropdown>
            </div>
            <div class="right">
              <input autocomplete="off" maxlength="12" formControlName="phone" type="text" [placeholder]="phone_no" (keyup)="mobileValidation($event)" appPhoneMask
                pInputText style="margin: 2px 0;" />
            </div>
          </div>
        </div>
        <div class="form-errors">
          <div *ngIf="!isPhoneNumberValid" translate>
            min_length_phone_number
          </div>
        </div>
        <div class="form-errors ui-g-12"
          *ngIf="editProfileForm.controls.phone.errors  && (editProfileForm.controls.phone.dirty || editProfileForm.controls.phone.touched)">
          <!-- <div [hidden]="!editProfileForm.controls.phone.errors.required" translate>
            contact_number_require
          </div> -->
          <!-- <div [hidden]="!editProfileForm.controls.phone.errors.minlength" translate>
            min_length_phone_number
          </div> -->
          <div [hidden]="!editProfileForm.controls.phone.errors.maxlength" translate>
            max_number_10
          </div>
          <div [hidden]="!editProfileForm.controls.phone.errors.pattern" translate>
            error_invalid_phone_number
          </div>
        </div>
      </div>


      <div class="form-buttons ui-g-12 ui-g-nopad pb-20">
        <button pButton type="button" class="save-btn" label="Save" [disabled]="!editProfileForm.valid || !isPhoneNumberValid"
          (click)="updateProfile($event)"></button>
        <button pButton class="cancel-btn" type="button" label="Cancel" (click)="closeEditProfile($event)"></button>
      </div>
    </div>
  </form>
</div>
