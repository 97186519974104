import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html'
})

export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  secondLoder = false;
  cssClass = 'loader-page-center-overlay';
  loaderClass = 'loader-overlay-panel';

  subscription: Subscription;
  subscribedSecondLoader: Subscription;
  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });

    this.subscribedSecondLoader = this.loaderService.secondLoderShow.subscribe(() => {
      this.show = false;
      this.secondLoder = true;
    });

    this.subscribedSecondLoader = this.loaderService.secondLoderHide.subscribe(() => {
      this.show = false;
      this.secondLoder = false;
    });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscribedSecondLoader.unsubscribe();
  }

}
