import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppSettings } from '../../modules/shared/app.settings';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  usernamePlaceholder;
  passwordPlaceHolder;
  signInLabel;
  isEmailInvalid: boolean;
  inputType = 'password';
  showPassword = false;
  selectOrganization = [{
    label: 'Harris Center',
    value: ''
  },
];
  @Output() isSignInEmitter: EventEmitter<any> = new EventEmitter();
  @Output() isForgotPasswordEmitter: EventEmitter<any> = new EventEmitter();

  signInForm = new FormGroup({
    organizationName: new FormControl(),
    username: new FormControl(),
    password: new FormControl()
  });
  organizationName: any;

  constructor(
    private formBuilder: FormBuilder,
    private commonBindingDataService: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.isEmailInvalid = false;
    // this.organizationName = this.commonBindingDataService.getLabel('Organizationname')
    this.usernamePlaceholder = this.commonBindingDataService.getLabel('Username');
    this.passwordPlaceHolder = this.commonBindingDataService.getLabel('Password');
    this.signInLabel = this.commonBindingDataService.getLabel('label_log_in');
    this.signInForm = this.formBuilder.group({
      username: ['', [
        Validators.required,
        Validators.pattern(AppSettings.EMAIL_PATTERN),
        Validators.maxLength(50)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(25)
      ]],
    });
  }

  doSignIn() {
    this.isSignInEmitter.emit(this.getLoginObject());
  }

  getLoginObject() {
    let signInData = {};
    if (this.signInForm.controls.username.errors === null) {
      signInData = {
        'email': this.signInForm.controls.username.value.trim(),
        'password': this.signInForm.controls.password.value.trim(),
        'tenantId': AppSettings.TENANT
      };
    } else {
      signInData = {
        'username': this.signInForm.controls.username.value.trim(),
        'password': this.signInForm.controls.password.value.trim(),
        'tenantId': AppSettings.TENANT
      };
    }
    return signInData;
  }

  onForgotPassword() {
    this.isForgotPasswordEmitter.emit();
  }

  eventHandler(event) {
    if (event.keyCode === 13 && this.signInForm.valid) {
      this.isSignInEmitter.emit(this.getLoginObject());
    }
  }


  validateEmail() {
    this.isEmailInvalid = false;
    if (this.signInForm.get('username').value) {
      // this.signInForm.controls.username.setValue(this.signInForm.get('username').value.trim())
      if (!AppSettings.EMAIL_PATTERN.test(this.signInForm.get('username').value)) {
        this.isEmailInvalid = true;
      }
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.inputType = (this.showPassword) ? 'text' : 'password';
  }
}
