<div class="ui-g page-header-c">
    <div class="ui-g-12 ui-md-6 ui-lg-6">
        <h1 class="page-title">Reports</h1>
    </div>
</div>

<div class="containar-layout">
    <div class="ui-g">
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12 txt-center">
            <img class="company-logo-background" src="/assets/images/temp/rainbowcare_logo.png">
            <div>
                <span class="report-text">The report </span> <span class="mt-20 filename-bold">{{ filesName }}</span>
                <span class="report-text"> will be automatically downloaded to your system. If the download doesn't
                    start automatically,</span>
                <div class="report-text"> Please <span class="click-link" (click)="downloadExcel()">click here</span> to
                    download the report manually.</div>
                <h5>{{ fileError }}</h5>
            </div>
        </div>
    </div>
</div>
