<div class="ui-g form-container">
  <form class="ui-g-12 ui-g-nopad" [formGroup]="bookingCancelform">
    <div class="form-field">
      <div class="form-control-c">
        <p-autoComplete field="driverName" [suggestions]="filteredDrivers" formControlName="driverName"
          (completeMethod)="search($event)" placeholder="Please select drivers" [size]="50" [dropdown]="true">
        </p-autoComplete>
      </div>
      <div class="form-errors"
        *ngIf="bookingCancelform.controls.driverName.errors  && (bookingCancelform.controls.driverName.dirty || bookingCancelform.controls.driverName.touched)">
        <div [hidden]="!bookingCancelform.controls.driverName.errors.required" translate>
          label_driver_required
        </div>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
        <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
          {{ErrorMessage.message}}
        </div>
      </div>
    </div>

    <div class="ui-g-12 ui-g-nopad form-buttons pt-33">
      <button pButton type="button" [label]="labelAssign" icon="fa fa-check" [disabled]="!bookingCancelform.valid"
        (click)="assignDriver()"></button>
      <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="labelCancel"
        (click)="cancel()"></button>
    </div>
  </form>
</div>
