import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirection-page',
  templateUrl: './redirection-page.component.html',
  styleUrls: ['./redirection-page.component.scss']
})
export class RedirectionPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  alert() {
    window.alert('Once the apps are live we will add the link.');
  }

}
