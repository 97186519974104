import { Component, OnInit, OnDestroy, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { LazyLoadEvent } from 'primeng';
import { Grid2Service } from '../mi-grid-2/grid2.service';
import { Grid2Model } from '../mi-grid-2/grid-2-model';
import { Table } from 'primeng/table';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-mi-grid-v2-multisort',
  providers: [Grid2Service],
  templateUrl: './mi-grid-multisort.component.html'
})

export class MiGridMultisortComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  search = '';
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() totalCount = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Input() url: any = '';
  @Input() bottomSearchBar: Boolean;
  @Input() isDetailScreen: Boolean;
  @Input() bottomExportButton = false;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 15;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  @Input() placeholderValue = 'Press enter to search';
  @ViewChild('dt') dt: Table;

  multiSortMeta = [];

  queryParams: any = '';
  enableResizer = 'false';
  @Input() overlayLoadingTemplate: any = '<span class="ag-overlay-loading-center b-red">' +
    'Please wait while your rows are loading</span>';
  datasource = [];
  model: Grid2Model;
  totalRecords = 0;
  sortF = '';
  first: any = 0;
  sortO = '';
  offset = 0;
  setRowLength = 10;
  searchText = '';
  selectedRecords: any = [];
  constructor(private elm: ElementRef, private uiService: UiService, private gridService: Grid2Service) { }

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent
      .subscribe((state: string) => {
        this.refreshList();
      });
  }

  onResize(event: any) {
    // this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    const that = this;
    this.gridService.loadGridData(that.getUrl(that.url)).subscribe(result => {
      const model = <Grid2Model>result;
      if (result !== undefined) {
        this.totalRecords = model.count;
        that.datasource = [];

        model.data.forEach(e => {
          that.columnDefs.forEach(c => {
            if (c && c.cellRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            } else if (c && c.cellRendererHtml) {
              e[c.field] = c.cellRendererHtml(e);
            }

            // that.actionButtonsDefs.forEach(d => {
            //   if (d.show) {
            //     e[d.field] = d.show(e);
            //   }
            // });

            that.actionButtonsDefs.forEach(d => {
              if (d.show) {
                e[d.field] = d.show(e);
              }
              if (d.title) {
                e[d.title] = d.title(e);
              }
            });
          });
          that.datasource.push(e);
        });
      }
      this.totalCount.emit(this.totalRecords);
      this.dt.first = this.offset;
    });
  }

  getUrl(url) {
    const that = this;
    let params = 'searchText=' + this.searchText + '&offset=' + this.offset + '&limit=' + this.setRowLength;
    params += '&sortColumn=';
    params += this.setMultiSort();
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = url;
    if (url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    return buildUrl;
  }

  setMultiSort() {
    let multiSortKey = '';
    const updatedMultiSortData = this.getUniqueMultiSortArraybyStaticData(this.multiSortMeta);
    updatedMultiSortData.forEach(element => {
      if (element.order === 1) {
        multiSortKey += '*' + element.field + ',';
      } else if (element.order === -1) {
        multiSortKey += '-' + element.field + ',';
      }
    });
    const rst = multiSortKey.slice(0, -1);
    return rst;
  }

  getUniqueMultiSortArraybyStaticData(arr) {
    const oldArray = [
      { field: 'tripId', order: 0 },
      { field: 'passengerName', order: 0 },
      { field: 'driverName', order: 0 },
      { field: 'pickupLocationName', order: 0 },
      { field: 'pickupDatetime', order: 0 },
      { field: 'tourStatus', order: 0 },
      { field: 'bookingType', order: 0 },
      { field: 'bookingStatus', order: 0 }
    ];

    for (let i = 0; i < oldArray.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (oldArray[i].field === arr[j].field) {
          oldArray[i].order = arr[j].order;
        }
      }
    }
    return oldArray;
  }

  resetData(url) {
    this.multiSortMeta = [];
    this.updateUrl(url);
  }

  getUniqueMultiSortArray(arr, comp) {
    const unique = arr
      .map((e) => {
        return e[comp];
      })
      .map((e, i, final) => {
        return final.lastIndexOf(e) === i && i;
      })
      .filter(e => {
        return arr[e];
      })
      .map(e => {
        return arr[e];
      });
    return unique;
  }

  public onQuickFilterChanged($event, dt) {
    if ($event.key === 'Enter') {
      this.applySearch(this.search);
      dt.first = 0;
    }
  }

  keyPress($event) {
    const invalidKeys = AppSettings.NOT_ALLOWED_KEYS;
    if (invalidKeys.indexOf($event.key) !== -1) {
      return false;
    }
  }

  updateUrl(url: string) {
    this.url = url;
    this.offset = 0;
    this.first = 0;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    }
    this.refreshList();
  }

  updateData(url: string) {
    this.url = url;
    const paramArray: any[] = this.url.split('?');
    if (paramArray.length > 1) {
      this.queryParams = paramArray[1];
    }
    this.refreshList();
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.first = event.first;
    this.setRowLength = event.rows;
    if (event.multiSortMeta !== undefined) {
      const multiSortMetaData = event.multiSortMeta;
      multiSortMetaData.forEach(element => {
        this.multiSortMeta.push(element);
      });
    }
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
  }

  onRowUnselect(event) {
  }

  actionBtnClick(event, data: any, action: string) {
    event.stopPropagation();
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchText = searchText;
    this.offset = 0;
    this.first = 0;
    this.fetchAndLoadData();
  }

  exportGridExcel(event: any) {
    const that = this;
    this.gridService.downloadExcelGridData(that.getUrl(that.exportUrl + '?' + this.searchText + '&' + this.queryParams));
  }

  exportGridPdf(event: any) {
    const that = this;
    this.gridService.downloadPdfGridData(that.getUrl(that.printUrl + '?' + this.searchText + '&' + this.queryParams));
  }

  getTitle(value) {
    return value.replace('<br>', ', ');
  }

}

