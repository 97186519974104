import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
@Injectable()

export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  currentMessageUpdated = new Subject<any>();
  constructor(private angularFireMessaging: AngularFireMessaging,
    private storageService: StorageService,
    private confirmationService: ConfirmationService,
    private router: Router, ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.storageService.setItem('notificationToken', token);
      },
      (err) => {
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
        this.currentMessageUpdated.next(payload);
        this.showForegroundNotification(payload);
      });
  }

  getPushMessage(): Observable<any> {
    return this.currentMessageUpdated.asObservable();
  }

  call(position: string) {
    // this.position = position;

    this.confirmationService.confirm({
        message: 'Incomming call',
        header: 'Call',
        icon: 'pi pi-info-circle',
        accept: () => {
            // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
            this.router.navigate(['twilio-video'], { queryParams: { incommingCall: true } });
        },
        reject: (type) => {
            // switch(type) {
            //     case ConfirmEventType.REJECT:
            //         this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
            //     break;
            //     case ConfirmEventType.CANCEL:
            //         this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
            //     break;
            // }
        },
        key: 'positionDialog'
    });
  }

  showForegroundNotification(payload: any) {
    const notify_data = payload['notification'];
    const title = notify_data['title'];
    const options = {
      body: notify_data['body'],
      icon: notify_data['icon'],
      // icon: 'https://landmarkdev.mi2.in/assets/images/temp/landmark_notification_icon.png',
      click_action: notify_data['click_action'],
      sound: 'default'
    };

    const myAudio = new Audio('../../../../assets/images/temp/notification.mp3');
    myAudio.play();

    const notify: Notification = new Notification(title, options);

    notify.onclick = event => {
      event.preventDefault();
      window.location.href = notify_data['click_action'];
    };
  }
}
