<app-loader></app-loader>
<div *ngIf="isReady" class="ui-g ui-g-nopad device-height">
  <app-header *ngIf="notificationService.canFetchNotification" class="top-nav ui-g-12 ui-g-nopad header-panel">
  </app-header>
  <div class="main-panel ui-g-12 ui-g-nopad">
    <app-sidebar *ngIf="notificationService.canFetchNotification" class="sidebar ui-g-12 ui-md-3 left-panel ui-g-nopad">
    </app-sidebar>

    <div [ngClass]="privacyPolicy? 'right-panel privacy-policy' : 'right-panel'">
      <app-page-header *ngIf="notificationService.canFetchNotification"></app-page-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<p-toast [style]="{marginTop: '40px'}"></p-toast>
<p-confirmDialog [style]="{width: '250px'}" key="positionDialog" position="top-right" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"
[acceptLabel]="'Answer'" [rejectLabel]="'Decline'"></p-confirmDialog>

<p-dialog [blockScroll]="true" modal="false" [showHeader]="false" styleClass="edit-profile-popup"
  [style]="{width: '370px'}" responsive="true" [(visible)]="onTwilioCallDialog">
  <div class="ui-g form-container">
    <div class="ui-g-12 header-dialog-content text-center">
      Join the meeting with {{ callerRoleName }}
    </div>
    <div class="ui-g-12 header-dialog-heading text-center">
      <p translate>{{ callerName }}</p>
    </div>
    <div class="ui-g-12">
      <div class="ui-g-12 ui-md-12 ui-sm-12 text-center">
        <!-- <img class="caller-aviator" src="/assets/images/temp/default_profile.png"> -->
        <img class="caller-aviator" src="{{profileUrl}}{{profileImage}}" onerror="src='/assets/images/temp/default_profile.png'" />
      </div>
    </div>
    <div class="ui-g-12 ui-g-nopad form-buttons">
      <div class="ui-g-6 ui-md-6 ui-sm-6 text-center">
        <img class="document" src="/assets/images/temp/decline.png" (click)="rejectCall()">
      </div>
      <div class="ui-g-6 ui-md-6 ui-sm-6 text-center">
        <img class="document" src="/assets/images/temp/accept.png" (click)="acceptCall()">
      </div>
    </div>
  </div>
</p-dialog>
