import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ErrorBindingService } from 'app/modules/shared/services/error-binding.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { NotificationsService } from 'app/modules/shared/services/notifications.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from './../../modules/shared/services/user-common.service';
import { environment } from 'environments/environment';
// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-ssosign-in-page',
  templateUrl: './ssosign-in-page.component.html'
})

export class SsosignInPageComponent implements OnInit {
  saml = '';
  signature = '';
  signInResponse: any;
  notification_token;
  device_details;
  signInLabel: any;
  signInVersion = environment.signInVersion;

  constructor(
    private route: ActivatedRoute,
    private commonBindingDataService: CommonBindingDataService,
    private notificationService: NotificationsService
  ) {  }

  ngOnInit() {
    this.notificationService.canFetchNotification = false;
    this.signInLabel = this.commonBindingDataService.getLabel('Login with SSO');

  }
  doSignIn(signInData) {
    window.location.href = environment.samlLoginUrl;
    // this.UserSignInSSOOkta(signInData);
  }

}
