import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { UiService } from 'app/modules/shared/services/ui.service';
import { UserCommonService } from 'app/modules/shared/services/user-common.service';

@Component({
  selector: 'app-report-download-authorization',
  templateUrl: './report-download-authorization.component.html',
  styleUrls: ['./report-download-authorization.component.scss']
})
export class ReportDownloadAuthorizationComponent implements OnInit {

  exportId: any;
  filesName: any;
  userId: any;
  fileError: any;
  constructor(
    private restApiService: RestApiService,
    private route: ActivatedRoute,
    private userCommonService: UserCommonService,
    private storageService: StorageService,
    private uiService: UiService,
  ) { }

  ngOnInit(): void {
    this.exportId = this.route.snapshot.paramMap.get('id');
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.userId = userObj.id;
    }
    if (this.exportId) {
       this.downloadExcel();
    }

  }

  downloadExcel() {
    // let url = '';
    // url = `/v1/secure/file-export/` + this.exportId;
    // return this.restApiService.excel('', url, 'report.xlsx', 'page-center');
    this.userCommonService.getReportAuthentication(this.exportId, this.userId).subscribe(result => {
        // window.open(result.signUrl, "_blank");
        this.filesName = result.fileName;
        return this.restApiService.excel('', '/secure/file/' + result.fileUrl, result.fileName, 'page-center');
    }, (error) => {
       this.uiService.showMessage(error.general[0].message, 'error');
       this.fileError = error.general[0].message;
    });
  }

}
