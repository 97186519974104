<div class="signin-box-container ui-g">
    <div class="signin-box-inner-container">
      <div class="signin-box ">
        <div class="sign-comatiner">
  
          <p-panel [showHeader]="false" [style]="{width: '440px', margin: 'auto'}">
            <div class="form-field ui-g-12 ui-g-nopad">
              <div class="form-field ui-g-12 ui-g-nopad text-center">
                <img class="company-logo-background" src='/assets/images/temp/rainbowcare_logo.png'>
              </div>
              <!-- <div class="form-field ui-g-12 switch-btn" translate>label_log_in</div> -->
              <div class="form-field ui-g-12"></div>
            </div>
            <div class="form-control-c">
                <!-- <div class="form-field ui-g-2 ui-g-nopad">
                  <img src=''>
                </div> -->
                <div class="form-control-c">
                  <button pButton label="{{signInLabel}}"  class='sign-in-buttons' type="button" 
                    (click)="doSignIn($event)"></button>
                </div>
              </div>
            <div class="version-number" style="margin-top: 10px; text-align: center;">Version {{ signInVersion }}</div>
            <div class="powered-by" style="margin-top: 10px; text-align: center;">Powered By: <a href="https://rainbow.health/">Rainbow Health LLC</a></div>
          </p-panel>
  
        </div>
      </div>
    </div>
  </div>
  