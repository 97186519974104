<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-8 ui-md-offset-2">
      <div class="support-team">
        <div class="support-content">
          If you are visiting the support page for help on data breach, then please check for the below points first:
        </div>


        <div class="support-content">


          <div class="support-header">
            1. Reset your password now
          </div>
          <div>

            If your password has been compromised, then change your password using either the change password feature
            post
            login or forgot password feature before login.
          </div>
        </div>


        <div class="support-content">
          <div class="support-header">
            2. Monitor your accounts
          </div>
          <div>

            In case there is any suspicious activity, Contact support at info@rainbow.health
          </div>

        </div>
        <div class="support-content">
          <div class="support-header">
            3. Watch your inbox carefully
          </div>
          <div>
            Opportunistic cybercriminals know that millions of victims of any given data breach are expecting
            somekind
            of
            communication regarding hacked accounts. These scammers will take the opportunity to send outphishing
            emails
            spoofed to look like they’re coming from those hacked accounts in an attempt to get youto give up
            personal
            information. Avoid opening emails from untrusted sources.
          </div>
        </div>


        <div>

          To get any further support on any kind of breach, please contact support at <a style="color: #007ad9;"
            href="mailto:info@rainbow.health">info@rainbow.health</a>
        </div>


      </div>
    </div>
  </div>
</div>