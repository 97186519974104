import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRunBumberFormat]'
})
export class RunBumberFormatDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (event) {
      let newVal = event;
      if (backspace && newVal.length <= 4) {
        newVal = newVal.substring(0, newVal.length - 1);
      }
      if (newVal.length === 0) {
        newVal = '';
      } if (newVal.length <= 12) {
        // newVal = newVal.replace(/^(\d{0,4})(\d{0,8})/, '$1-$2');
        newVal = newVal.replace(/^([a-zA-Z0-9]{4})([a-zA-Z0-9]{8})/, '$1-$2');
      }
      this.ngControl.valueAccessor.writeValue(newVal);
      // this.ngControl.control.setValue(newVal);
      // [a-zA-Z0-9]{3}[-][a-zA-Z0-9]{8}
    }
  }

}
