import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettings } from '../../../shared/app.settings';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { Message } from 'primeng/api';
import { UiService } from '../../services/ui.service';
declare const google: any;

@Component({
  selector: 'app-booking-history-details',
  templateUrl: './booking-history-details.component.html'
})

export class BookingHistoryDetailsComponent implements OnInit {
  bookingId: any;
  passengerId: any;
  map: any;
  marker;
  directionsService = new google.maps.DirectionsService;
  directionsDisplay = new google.maps.DirectionsRenderer;
  maxRating = AppSettings.Max_Rating;
  sendEmailPopup = false;
  refundAmountPopup = false;
  labelBack;
  tripDetailsInfo = [];
  driverInfo = null;
  estimatesInfo = null;
  passengerInfo = null;
  vehicleInfo = null;
  invoiceInfo = null;
  tourBookingInfo = null;
  tripStatistics = [];
  fareBreakDown = [];
  tripNote: any = false;
  biddings = [];

  bookingErrors = [{
    'bookingInfo.amount': false,
    'bookingInfo.profilePassword': false
  }];

  mapImageUrl: string = AppSettings.GET_FILE_URL;
  routeImageUrl = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonBindingDataService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.bookingId = this.route.snapshot.queryParams['id'];
    this.passengerId = this.route.snapshot.queryParams['passengerId'];
    this.labelBack = this.commonService.getLabel('label_back');
    this.tripNote = this.commonService.getTenantConfigValue(AppSettings.TRIP_NOTE);
    this.getBookingDetails();
  }

  getBookingDetails() {
    this.commonService.fetchTourBooking(this.bookingId).subscribe(results => {
      this.tripDetailsInfo = results.tripDetails;
      if (this.tripDetailsInfo) {
        this.tripDetailsInfo.splice(0, 1);
      }
      this.driverInfo = results.driverInfo;
      this.estimatesInfo = results.estimatesInfo;
      this.tourBookingInfo = results.tourBookingInfo;
      this.invoiceInfo = results.invoiceInfo;
      this.vehicleInfo = results.vehicleInfo;
      this.tripStatistics = results.tripStatistics;
      this.passengerInfo = results.passengerInfo;
      this.fareBreakDown = results.fareBreakDownList;

      if (this.invoiceInfo.staticMapImage !== undefined && this.invoiceInfo.staticMapImage !== null) {
        this.routeImageUrl = this.mapImageUrl + '/' + this.invoiceInfo.staticMapImage;
      }

      if (this.tourBookingInfo !== null) {
        const origin = {
          lat: this.tourBookingInfo.sourceLatitude,
          lng: this.tourBookingInfo.sourceLongitude,
        };
        const destingation = {
          lat: this.tourBookingInfo.destinationLatitude,
          lng: this.tourBookingInfo.destinationLongitude
        };
        this.calculateAndDisplayRoute(origin, destingation);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  calculateAndDisplayRoute(origin, destination, currentLocation?) {
    const map = new google.maps.Map(document.getElementById('tripMap'), {
      center: origin,
      zoom: 6,
      gestureHandling: AppSettings.COOPERATIVE,
      zoomControl: true
    });

    const directionsDisplay = new google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: AppSettings.DEFAULT_MAP_ROUTE_COLOR
      }
    });

    directionsDisplay.setMap(map);

    this.directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: AppSettings.DRIVING
    }, (response, status) => {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
        if (currentLocation) {
          this.marker = new google.maps.Marker;
          this.marker.setMap(map);
          this.marker.setIcon('/assets/images/temp/taxi_busy.png');
          this.marker.setPosition(currentLocation);
        }
      } else {
        this.mapMessage(status);
      }
    });
  }

  onClickBack() {
    this.router.navigate(['passengers/passenger-details'], { queryParams: { passengerId: this.passengerId } });
  }

  mapMessage(status) {
    this.uiService.showMessage(status, AppSettings.MESSAGE_TYPES.SUCCESS);
  }

}
