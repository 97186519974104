<div class="track-my-location-container ui-g">
  <div class="track-my-location">

    <div class="header ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
      <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
        <a>
          <img class="company-logo-background" width="200" src="/assets/images/temp/rainbowcare_logo.png">
        </a>
      </div>
      <div *ngIf="showTrackMyLocationPage" class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12 contact-info">
        <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12"></div>
        <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
          <strong translate>label_contactNumber</strong>:&nbsp;
          <span class="contact-info">
            {{ locationDetails.tenantInfo.tenantPhoneNum !== 'null null' ? locationDetails.tenantInfo.tenantPhoneNum : '' }}
          </span>
        </div>
      </div>
    </div>

    <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
      <div class="seperator"></div>
    </div>
    <div>
      <div *ngIf="showTrackMyLocationPage" class="location-info ui-g-12 ui-md-12 ui-lg-12">
        <div class="ui-g-2 ui-md-12 ui-lg-2 ui-sm-12 content text-center">
          <img class="driver-logo" [src]="imageUrl">
        </div>

        <div *ngIf="locationDetails.tourBookingDetailsDto !== undefined && 
          locationDetails.tourBookingDetailsDto !== null">
          <div class="ui-g-2 ui-md-6 ui-lg-2 ui-sm-5 content">
            <div *ngIf="locationDetails.tourBookingDetailsDto.driverInfo !== undefined &&
            locationDetails.tourBookingDetailsDto.driverInfo !== null">
              <div class="content-header" translate>label_driver</div>
              <div class="content-info">
                <div class="data-info">{{ locationDetails.tourBookingDetailsDto.driverInfo.firstName }}
                  {{ locationDetails.tourBookingDetailsDto.driverInfo.lastName }}</div>
              </div>
            </div>

            <div class="mt-20" *ngIf="locationDetails.tourBookingDetailsDto.passengerInfo !== undefined &&
            locationDetails.tourBookingDetailsDto.passengerInfo !== null">
              <div class="content-header" translate>label_member</div>
              <div class="content-info">
                <div class="data-info">{{ locationDetails.tourBookingDetailsDto.passengerInfo.firstName }}
                  {{ locationDetails.tourBookingDetailsDto.passengerInfo.lastName }}</div>
              </div>
            </div>

          </div>

          <div class="ui-g-3 ui-md-6 ui-lg-3 ui-sm-7 content mb-10">
            <div *ngIf="locationDetails.tourBookingDetailsDto.vehicleInfo!== undefined
            && locationDetails.tourBookingDetailsDto.vehicleInfo !== null">
              <div class="content-header" translate>label_vehicle_details</div>
              <div class="content-info">
                <table>
                  <tbody>
                    <tr>
                      <td translate>label_vehicle_make_with_colon</td>
                      <td class="data-info">{{ locationDetails.tourBookingDetailsDto.vehicleInfo.makeModel }}</td>
                    </tr>
                    <tr>
                      <td translate>label_model_with_colon</td>
                      <td class="data-info">{{ locationDetails.tourBookingDetailsDto.vehicleInfo.modelName }}</td>
                    </tr>
                    <tr>
                      <td translate>label_vehicle_number_with_colon </td>
                      <td class="data-info">{{ locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleNumber }} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="ui-g-4 ui-md-12 ui-lg-4 ui-sm-12 content">
            <div *ngIf="locationDetails.tourBookingDetailsDto.tourBookingInfo !== undefined &&
            locationDetails.tourBookingDetailsDto.tourBookingInfo !== null">
              <div class="content-header" translate>label_trip_details</div>
              <div class="content-info">
                <p>
                  <i class="fa fa-circle start mr-5"></i>
                  {{ locationDetails !== null ? locationDetails.tourBookingDetailsDto.tourBookingInfo.pickupLocationName : '' }}
                </p>
                <p>
                  <i class="fa fa-circle end mr-5"></i>
                  {{ locationDetails !== null ? locationDetails.tourBookingDetailsDto.tourBookingInfo.dropLocationName : '' }}
                </p>
                <div>
                  <span class="bold-status" translate>label_pickup_date_time</span> :
                  <span class=" pl-10">
                    {{ (locationDetails !== null ? locationDetails.tourBookingDetailsDto.tourBookingInfo.pickupDateTime : '') |
                    datetimeformat }}
                  </span>
                </div>
                <div>
                  <span class="bold-status" translate>label_status_with_colon</span> :
                  <span class="data-info pl-10 data-info-status">{{ tourStatus }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="ui-g-12 ui-md-12 ui-lg-12 ui-sm-12 tracking-map-container mb-10">
        <div id="trackLocationMap" class="map"></div>
      </div>

    </div>
  </div>
</div>

<div class="track-my-location-error" *ngIf="!showTrackMyLocationPage">
  {{errorMessage}}
</div>