import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppSettings } from './../../app.settings';
import { StorageService } from './../../services/storage.service';
import { SidebarService } from './../../services/sidebar.service';
import { Subscription } from 'rxjs';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent implements OnInit {
  items: MenuItem[];
  subscription: Subscription;
  isMultipleTabOpen: any = false;
  user;

  constructor(
    private storageService: StorageService,
    private sidebarService: SidebarService,
    private commonBindingDataService: CommonBindingDataService,
    private router: Router
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/provider-availability') || event.url.includes('/appointments/appointment-details') ||
          event.url.includes('/appointments/reschedule-appointment') || event.url.includes('/appointments/re-assign-provider') ||
          event.url.includes('/appointments/book-appointment') || event.url.includes('/appointments/create-appointment')) {
        } else {
          localStorage.setItem('availabilityFilters', null);
        }
      });
  }

  ngOnInit() {
    this.initSidebarItems();
    this.subscription = this.sidebarService.sideState
      .subscribe((state: any) => {
        this.updateItems(state);
      });

    this.commonBindingDataService.redrawSidebar.subscribe(() => {
      this.initSidebarItems();
    });
  }

  initSidebarItems() {
    this.items = [];
    this.user = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (this.items.length <= 0) {
      if (this.user !== null && this.user !== undefined) {
        this.items = this.getMenu();
      }
    }
    this.updateItems();
    this.manageActiveStatus();
  }

  updateItems(state?) {
    if (state) {
      this.items = state;
    }
    this.items = this.removeDuplicates(this.items, 'label');
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  private manageActiveStatus() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.routerLink) {
        if (item.routerLink.length >= 1 && document.location.pathname === item.routerLink[0]) {
          item.expanded = true;
        }
      }
    }
  }

  selectedMenuClick(item) {
    this.storageService.setItem('selectedMenu', item.srcElement.textContent.trim());
  }
  getMenu() {
    return this.sidebarService.sideBarMenu;
  }


 }
