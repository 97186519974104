import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { CommonBindingDataService } from './../services/common-binding-data.service';

@Directive({
  selector: '[appAccessProvider]'
})

export class AccessProviderDirective implements OnChanges {
  @Input() accessCode;
  private innerElement: HTMLElement;
  private menuAccessList: any[];

  constructor(refElement: ElementRef, private commonBindingDataService: CommonBindingDataService) {
    this.innerElement = refElement.nativeElement;
  }

  ngOnChanges() {
    this.menuAccessList = this.commonBindingDataService.getSelectedMenuAccessesList();
    const hasAccess = this.menuAccessList.find((elm) => {
      return elm.accessCode === this.accessCode;
    });
    if (!hasAccess) {
      this.innerElement.style.display = 'none';
    }
  }

}
