import { Message } from 'primeng/api';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TenantService } from './../../services/tenant.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AppSettings } from '../../../shared/app.settings';
import { UiService } from 'app/modules/shared/services/ui.service';

@Component({
  selector: 'app-editable-privacy-policy',
  templateUrl: './editable-privacy-policy.component.html'
})

export class EditablePrivacyPolicyComponent implements OnInit {
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Input() policies = '';

  editPoliciesForm = new FormGroup({
    policies: new FormControl()
  });
  labelSave;
  labelCancel;
  labelheader;
  privacyPolicyData;

  constructor(
    private formBuilder: FormBuilder,
    private tenantService: TenantService,
    private commonService: CommonBindingDataService) { }

  ngOnInit() {
    this.labelSave = this.commonService.getLabel('label_save');
    this.labelCancel = this.commonService.getLabel('label_cancel');
    this.labelheader = this.commonService.getLabel('label_edit_privacy_policy');

    this.editPoliciesForm = this.formBuilder.group({
      policies: [this.policies, [Validators.required]]
    });
  }

  onbind(data) {
    this.privacyPolicyData = data;
  }

  addPrivacyPolicies() {
    const data = {
      id: AppSettings.TENANT,
      privacyPolicy: this.editPoliciesForm.controls.policies.value
    };

    this.tenantService.setPrivacyPolicy(data).subscribe(results => {
      this.editPoliciesForm.reset();
      this.isPopSave.emit(results);
    }, (error) => {
      this.isPopCancel.emit(error);
    });

  }
  Cancel() {
    this.isPopCancel.emit();
  }

}
