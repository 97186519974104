import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class TenantService {

  constructor(private restApiService: RestApiService) { }

  getFAQ(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/faq', 'page-center');
  }

  addFAQ(data, id?): Observable<any> {
    if (id !== null) {
      return this.restApiService.put('', '/secure/tenants/faq/' + id, data, 'page-center');
    } else {
      return this.restApiService.post('', '/secure/tenants/faq', data, 'page-center');
    }
  }

  removeFAQ(id): Observable<any> {
    return this.restApiService.delete('', '/secure/tenants/faq/' + id, 'page-center');
  }

  getPartners(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/partners', 'page-center');
  }

  addPartners(data, id?): Observable<any> {
    if (id !== null) {
      return this.restApiService.put('', '/secure/tenants/partners/' + id, data, 'page-center');
    } else {
      return this.restApiService.post('', '/secure/tenants/partners', data, 'page-center');
    }
  }

  removePartner(id): Observable<any> {
    return this.restApiService.delete('', '/secure/tenants/partners/' + id, 'page-center');
  }

  getAboutUs(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/about-us', 'page-center');
  }

  setCompnayInfo(data): Observable<any> {
    return this.restApiService.put('', '/secure/tenants/aboutus', data, 'page-center');
  }

  getTearmAndCondition(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/terms-and-conditions', 'page-center');
  }

  setTermCondition(data): Observable<any> {
    return this.restApiService.put('', '/secure/tenants/termsandconditions', data, 'page-center');
  }

  getPrivacyPolicy(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/privacy-policy', 'page-center');
  }

  setPrivacyPolicy(data): Observable<any> {
    return this.restApiService.put('', '/secure/tenants/privacypolicy', data, 'page-center');
  }

  setRentalPolicy(data): Observable<any> {
    return this.restApiService.put('', '/secure/tenants/rental-policy', data, 'page-center');
  }

  setChauffeurPolicy(data): Observable<any> {
    return this.restApiService.put('', '/secure/tenants/chauffeur-policy', data, 'page-center');
  }

}
