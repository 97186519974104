<div class="ui-g page-header-c">
    <div class="ui-g ui-g-12 header-wrapper">
        <div class="ui-g-12 ui-md-6 ui-lg-9">
            <h1 class="page-title">Open Shifts</h1>
        </div>
        <div class="ui-g-12 ui-md-6 ui-lg-3">
            <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
                <div class="float-right">
                    <p-checkbox class="padding-0" value="0" binary="true" (onChange)="hideFilter($event)">
                    </p-checkbox>
                    <span class="hidefilter-txt" translate> Hide Filter</span>
                </div>
            </div>
        </div>
    </div>

    <div class="containar-layout manage-shift-container" *ngIf="!hideFilterSection">
        <div class="ui-g">
            <div class="ui-g-12 grid-wrap">
                <div class="ui-grid-left ui-g-12 ui-md-12 ui-lg-9">
                    <div class="form-field ui-g-12 ui-md-12 ui-lg-3">
                        <div class="form-label">
                            <label for="driverAvailability" translate></label>
                        </div>
                        <div class="form-control-c">
                            <input autocomplete="off" type="text" pInputText [(ngModel)]="searchedTeam"
                                (keyup)="searchTeam()" [placeholder]="'label_search_user' | translate" />
                        </div>
                    </div>

                    <div class="form-field ui-g-12 ui-md-4 ui-lg-3">
                        <div class="form-label">
                            <label for="driverAvailability" translate>label_date</label>
                        </div>
                        <div class="form-control-c">
                            <p-calendar [showIcon]="true" [(ngModel)]="date" (onSelect)="changeFilter()"
                                [maxDate]="maxDate" [placeholder]="'label_date' | translate">
                            </p-calendar>
                        </div>
                    </div>

                    <div class="form-field ui-g-12 ui-md-4 ui-lg-3">
                        <div class="form-label">
                            <label for="provider" translate>label_status</label>
                        </div>
                        <div class="form-control-c">
                            <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" [resetFilterOnHide]="true"
                                (onChange)="changeFilter()">
                            </p-dropdown>
                        </div>
                    </div>
                    <i class='fa fa-refresh reste-btn refresh-btn' (click)="onResetFilter()"></i>
                    <!-- <button class="refresh-btn" pButton (click)="openShift()" type="button"
                        [label]="'label_open_shift' | translate" pButton></button> -->
                </div>
                <div class="ui-grid-right ui-g-12 ui-md-12 ui-lg-3">
                    <div class="ui-right-block ui-g-12 ui-md-6 ui-lg-4"
                        *ngFor="let openShiftStatus of openShiftStatusList"><span class="rect-block"
                            [ngClass]="manageOpenShiftsService.convertToCssClass(openShiftStatus.label)"></span><span
                            class="rect-text">{{openShiftStatus?.label}}</span></div>
                </div>
            </div>
        </div>
    </div>

    <div class="containar-layout">
        <div class="table-section">
            <div class="column-wrapper" *ngFor="let shiftDetail of shiftDetails; let i = index">
                <div class="column-head" [class.custom-border]="i === 0">{{shiftDetail.dateStr | weekMonthDate }}</div>
                <div class="column-body">
                    <div class="card-wrapper" *ngFor="let openShift of shiftDetail.openShiftList"
                        (click)="openTeamPopup(openShift)">
                        <app-my-open-shifts-slot-card [teamDetails]="openShift"
                            [openShiftStatusList]="openShiftStatusList">
                        </app-my-open-shifts-slot-card>
                    </div>
                    <div class="no-cards-wrapper" *ngIf="!shiftDetail.openShiftList.length">
                        <span>No</span>
                        <span>Open</span>
                        <span>Shifts</span>
                        <span>Published</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <p-dialog [blockScroll]="true" modal="true" [showHeader]="false" position="center" responsive="true"
        [style]="{width: '450px', height: 'auto'}" styleClass="add-routes-popup" [(visible)]="hasShowPublishShiftPopUp"
        responsive="true" ng-style="overflow: auto" [contentStyle]="{'overflow':'auto'}">
        <app-publish-open-shift (publishOpenShiftEmitter)="onClickOfPopupButton($event)"></app-publish-open-shift>
    </p-dialog> -->

    <p-dialog class="pop-shift" [blockScroll]="true" modal="true" header="Open Shifts" position="center"
        responsive="true" [style]="{width: '650px', height: 'auto', 'max-width': '90%'}" styleClass="open-shift-popup"
        [(visible)]="hasShowOpenShiftPopUp" responsive="true" ng-style="overflow: auto"
        [contentStyle]="{'overflow':'auto'}">
        <div class="form-container">
            <div class="ui-g ui-g-12 ui-md-12 ui-lg-6 shift-wrapper">
                <div class="label ui-g-12 ui-md-12 ui-lg-6">Applications</div>
                <div class="value ui-g-12 ui-md-12 ui-lg-6">{{currentTeamDetails?.applicationCount}} Requests</div>
            </div>
            <div class="ui-g  ui-g-12 ui-md-12 ui-lg-6 shift-wrapper">
                <div class="label ui-g-12 ui-md-12 ui-lg-6">Broadcasted On</div>
                <div class="value ui-g-12 ui-md-12 ui-lg-6">{{ getBroadcastedCreatedBy(currentTeamDetails) }}</div>
            </div>

            <div class="ui-g  ui-g-12 ui-md-12 ui-lg-6 shift-wrapper">
                <div class="label ui-g-12 ui-md-12 ui-lg-6">Shift Date</div>
                <div class="value ui-g-12 ui-md-12 ui-lg-6">{{currentTeamDetails?.dateStr | date: 'MMM dd, YYYY'}}</div>
            </div>
            <div class="ui-g  ui-g-12 ui-md-12 ui-lg-6 shift-wrapper">
                <div class="label ui-g-12 ui-md-12 ui-lg-6">Boradcasted By</div>
                <div class="value ui-g-12 ui-md-12 ui-lg-6">{{currentTeamDetails?.createdByFirstName}} {{currentTeamDetails?.createdByLastName}}</div>
            </div>
            <!-- </div> -->
            <div class="ui-g  ui-g-12 ui-md-12 ui-lg-12 shift-wrapper">
                <div class="label ui-g-12 ui-md-12 ui-lg-3">Shift Time</div>
                <div class="value ui-g-12 ui-md-12 ui-lg-9">{{currentTeamDetails?.startDateTimeStr}} - {{currentTeamDetails?.endDateTimeStr}}</div>
            </div>
            <div class="ui-g  ui-g-12 ui-md-12 ui-lg-12 shift-wrapper">
                <div class="label ui-g-12 ui-md-12 ui-lg-3">Notes</div>
                <div class="value ui-g-12 ui-md-12 ui-lg-9">{{currentTeamDetails?.notes ? currentTeamDetails?.notes : '-'}}</div>
            </div>
            <div class="form-buttons ui-g-12 ui-g-nopad pt-20 pb-20" [class.d-none]="currentTeamDetails?.applicationStatus !== 8010">
                <button class="save-btn" pButton type="button" [disabled]="currentTeamDetails?.applicationStatus === 8020 || isSubmitted" [label]="'label_apply' | translate"
                 (click)="applyLeave(true)"></button>
                <button class="cancel-btn" pButton type="button" [disabled]="currentTeamDetails?.applicationStatus === 8070 || isSubmitted" [label]="'label_deny' | translate"
                    (click)="applyLeave(false)"></button>
            </div>
        </div>
    </p-dialog>