import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Message } from 'primeng/api';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-mg1',
  templateUrl: './notification-mg1.component.html'
})

export class NotificationMG1Component implements OnInit {
  @Input() notifications;
  @Input() notificationCount;
  @Output() loadMoreNotification: EventEmitter<any> = new EventEmitter();
  @Input() showMoreOff = false;
  message: Message[] = [];
  labelLoadMore = '';

  constructor(private commonBindingDataService: CommonBindingDataService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.labelLoadMore = this.commonBindingDataService.getLabel('label_load_more_notifications');
  }

  onLoadMore() {
    this.loadMoreNotification.emit();
  }

  notificationRout(event) {
    const extaraParams = event['extraParams'].split('~');
    if (extaraParams[0] === 'TEAM_DISPATCH_STATUS_UPDATED_WEB' || extaraParams[0] === 'ADMIN_NEW_DISPATCH_CREATED_WEB'
    || extaraParams[0] === 'ADMIN_TEAM_DISPATCH_ASSIGNED_WEB' || extaraParams[0] === 'DISPATCH_ASSIGN_REMAINDER_WEB'
    || extaraParams[0] === 'ADMIN_CANCELLED_DISPATCH_WEB_ONSCENE') {
      this.router.navigate(['/dispatch-request/edit-dispatch-request-info'], { queryParams: { id: extaraParams[1]}})
      .then(() => {
        window.location.reload();
      });
    } else if (extaraParams[0] === 'CONTACT_FORM_CREATED_WEB' || extaraParams[0] === 'CONTACT_FORM_UPDATED_WEB' || extaraParams[0] === 'FOLLOW_UP_CREATED_WEB') {
      this.router.navigate(['/contact-form/edit-contact-form'], { queryParams: { id: extaraParams[1]}})
      .then(() => {
        window.location.reload();
      });
    }
  }

  notificationStatus(notificationObj) {
    const extaraParams = notificationObj['extraParams'].split('~');
    if (extaraParams[0] === 'TEAM_DISPATCH_STATUS_UPDATED_WEB' || extaraParams[0] === 'CONTACT_FORM_CREATED_WEB'
     || extaraParams[0] === 'CONTACT_FORM_UPDATED_WEB' || extaraParams[0] === 'ADMIN_NEW_DISPATCH_CREATED_WEB'
     || extaraParams[0] === 'ADMIN_TEAM_DISPATCH_ASSIGNED_WEB' || extaraParams[0] === 'DISPATCH_ASSIGN_REMAINDER_WEB'
     || extaraParams[0] === 'ADMIN_CANCELLED_DISPATCH_WEB_ONSCENE' || extaraParams[0] === 'FOLLOW_UP_CREATED_WEB'
     || extaraParams[0] === 'ADMIN_PROVIDER_DISPATCH_NOTES_WEB' || extaraParams[0] === 'ADMIN_PROVIDER_DISPATCH_SAFE_WEB'
     || extaraParams[0] === 'DISPATCH_ASSIGN_REMAINDER_MOBILE' || extaraParams[0] === 'PROVIDER_DISPATCH_ASSIGNED_WEB') {
      return true;
    } else {
    return false;
    }
  }


}
