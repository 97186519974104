import { Component, OnInit } from '@angular/core';
import { AppSettings } from './modules/shared/app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { UserCommonService } from './modules/shared/services/user-common.service';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { StorageService } from './modules/shared/services/storage.service';
import { CommonBindingDataService } from './modules/shared/services/common-binding-data.service';
import { UiService } from './modules/shared/services/ui.service';
import { MessageService } from 'primeng';
import { MessagingService } from './modules/shared/services/messaging.service';
import { Subscription } from 'rxjs';
import { DeploymentRefreshService } from './modules/shared/services/deployment-refresh.service';
declare const google: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'NEMT';
  showHeaderAndSidebar = false;
  isMetadataLoaded: any = false;
  googleIntevalCheck;
  isReady = false;
  message;
  deviceInfo = null;
  subscription: Subscription;
  onTwilioCallDialog = false;
  userInfo: any;
  callerName: string;
  profileUrl = '';
  profileImage: any;
  callerRoleName: any;
  privacyPolicy = false;
  isLogout = false;

  constructor(
    private router: Router,
    public notificationService: NotificationsService,
    private storageService: StorageService,
    public userCommonService: UserCommonService,
    private messageService: MessageService,
    private uiService: UiService,
    private messagingService: MessagingService,
    private deploymentRefreshService: DeploymentRefreshService,
  ) {
    this.router.events.subscribe((url: any) => {
      if (url.url !== undefined && url.url !== null) {
        const browserUrl = url.url.split('?');
        if (AppSettings.HEADER_NOT_REQUIRED.includes(browserUrl[0])) {
          this.showHeaderAndSidebar = false;
        } else {
          this.showHeaderAndSidebar = true;
        }
      }
      if (url instanceof NavigationEnd) {
        if (url.url === '/privacy-policy') {
      this.privacyPolicy = true;

        } else {
      this.privacyPolicy = false;

        }
      }
    });
    this.deploymentRefreshService.updateClient();
  }

  ngOnInit() {
    // this.isLogout = this.storageService.getItemLocalStorage('isLogout');
    // this.userInfo = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.uiService.showMessageEvent.subscribe(result => {
      this.messageService.add({ severity: result.severity, summary: result.summary, detail: result.detail });
    });
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    this.subscription = this.messagingService.getPushMessage().subscribe(msg => {
      if (msg.notification.body.includes('has joined the scheduled appointment session. Please join')) {
        this.onTwilioCallDialog = true;
        this.userInfo = this.storageService.getItem(AppSettings.USER_DETAILS);
        this.notificationService.getTwilioToken(this.userInfo.id).subscribe(results => {
          results.callParticipantsList.forEach(ele => {
            if (ele.userId !== this.userInfo.id) {
              this.callerName = ele.firstName + ' ' + ele.lastName;
              this.profileImage = ele.profileImage;
              this.callerRoleName = ele.roleName;
            }
          });
        }, (error) => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
        });
      }

      if (msg.notification.body.includes('is might be busy at the moment')
          || msg.notification.body.includes('you have missed call from')) {
        this.onTwilioCallDialog = false;
      }
    });

    if (this.userCommonService.isUserMetadataPresent === false) {
      const idleSessionTime = +this.storageService.getItemLocalStorage(AppSettings.INTERVAL);
      if (idleSessionTime) {
        this.userCommonService.getAdminUserMetaData();
        this.googleIntevalCheck = setInterval(() => {
          if (typeof google === 'object' && typeof google.maps === 'object') {
            this.initProcess();
          }
        }, 1000);
      } else {
        this.initProcess();
      }
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  initProcess() {
    this.isReady = true;
  }

  acceptCall() {
    this.onTwilioCallDialog = false;
    this.notificationService.getTwilioToken(this.userInfo.id).subscribe(results => {
      this.router.navigate(['twilio-video'], { queryParams: { incommingCall: true } });
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  rejectCall() {
    this.onTwilioCallDialog = false;
    const receivedID = this.userInfo.id;
    this.notificationService.getTwilioToken(receivedID).subscribe(results => {
      const data = {
        'initiatorId' : results.initiatorId,
        'receiverId' : receivedID,
        'roomSid': results.roomSid,
        'rejectType': 'CALL_REJECTED_BY_RECEIVER'
      };
    }, (error) => {
        // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

}
