<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img class="company-logo-background" src='./assets/images/temp/rainbowcare_logo.png'>
          </div>
          <div class="form-field ui-g-12 switch-otp">
          <div class="form-field ui-g-12 switch-otp" translate>label_forgot_password</div>
            <p class="buttom-text" translate>label_otp_send_text</p>
          </div>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
          <form [formGroup]='otpForm'>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="username" translate>label_otp_header</label>
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="otpInput" class="bottom-border" type="text" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="otpForm.controls.otpInput.errors  && (otpForm.controls.otpInput.dirty || otpForm.controls.otpInput.touched)">
                <div [hidden]="!otpForm.controls.otpInput.errors.required" translate>
                  invalid_otp
                </div>
                <div [hidden]="!otpForm.controls.otpInput.errors.pattern" translate>
                  route_pattern
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12">
              <div class='resend-otp-btn' >
                <span [hidden]="otpCounterTimer == 0">Resend OTP in {{otpCounterTimer}} seconds.</span>
              </div>
              <div class="resend-btn-right"><a style="text-align: right;cursor: pointer;" (click)="resendOtp($event)" *ngIf="otpCounterTimer == 0" >Resend OTP</a></div>

              <div class="form-buttons ui-g-12 ui-g-nopad">
                <button style="margin-right: 0;" class='signin-save-btn' type="button" label="Continue" [disabled]="!otpForm.valid"
                  (click)="changePassword($event)" pButton></button>
                <!-- <button class='signin-save-btn' type="text" label="Resend OTP" [disabled]="otpCounterTimer !== 0"
                  (click)="resendOtp($event)" pButton></button> -->

                  <div class="form-field ui-g-12 ui-md-12 ui-lg-12 back-to-lgn text-center">
                    <span class="back-login">Back to <a href="./#/signin">Login</a></span>
                    </div>
              </div>
            </div>

          </form>
        </div>
        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>
