import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { RestApiService } from '../../services/rest-api.service';
import { AppSettings } from '../../app.settings';
@Component({
  selector: 'app-secure-image',
  templateUrl: './secure-image.component.html',
  styleUrls: ['./secure-image.component.scss']
})
export class SecureImageComponent implements OnInit {

  @Input() private src: string;
  @Input() isDocument: Boolean = false;
  showImage: Boolean = false;
  dataUrl$: any;

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer, private restApiService: RestApiService) {
  }

  ngOnInit() {

  }

  ngOnChanges(): void {
    this.dataUrl$ = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
    if (this.isDocument) {
      this.dataUrl$ = AppSettings.DEFAULT_DOCUMENT_IMAGE_URL;
    } else {
      this.dataUrl$ = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
    }
    if (this.src) {
      this.loadImage(this.src).subscribe(result => {
        this.dataUrl$ = result;
      });
    }
  }



  private loadImage(url: string): Observable<any> {
    // return this.httpClient.get(url, {
    //   headers: this.restApiService.getHttpClientHeaders(),
    //   responseType: 'blob', withCredentials: true
    // }).pipe(map((r: Response) => {
    //   return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(r));
    // })
    // );

    return this.httpClient.get(url, {
      headers: this.restApiService.getHttpClientHeaders(),
      responseType: 'blob', withCredentials: true
    }).pipe(map((r) => {
      return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(r));
    })
    );

  }

  openImage(event: any) {
    this.showImage = true;
  }

}
