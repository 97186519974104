import { Component, OnInit, Input } from '@angular/core';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html'
})

export class PieChartComponent implements OnInit {
  colorArray = AppSettings.PIE_CHART_COLOURS;

  @Input() data: any[] = [];
  @Input() header: string;
  headerStr = AppSettings.HEADER;
  buildData: any;

  constructor() {
  }

  ngOnInit() {
    this.buildData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }
      ]
    };
    this.headerStr = this.header;
    this.data.forEach((item, index) => {
      let color = this.getRandomColor();
      if (this.colorArray.length > index) {
        color = this.colorArray[index];
      }
      this.buildData.labels.push(item.label);
      this.buildData.datasets[0].data.push(item.value);
      this.buildData.datasets[0].backgroundColor.push(color);
      this.buildData.datasets[0].hoverBackgroundColor.push(color);
    });
  }

  getRandomColor() {
    return '#' + Math.random().toString(16).substr(-6);
  }

}
