import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppSettings } from '../app.settings';

@Pipe({
  name: 'timeformat'
})
export class TimeformatPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    return moment(value).format(AppSettings.TIME_FORMATTER);
  }

}
