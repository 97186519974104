<div (window:resize)="onResize($event)" class="mi-grid-component">
  <div class="search-and-export-data-panel">
    <input autocomplete="off" *ngIf="!bottomSearchBar" type="text" class="grid-search-bar top" [(ngModel)]="search"
      (keyup)="onQuickFilterChanged($event, dt)" (keypress)="keyPress($event)" [placeholder]="placeholderValue" (paste)="onPaste($event)"/>
    <label for="search-bar" *ngIf="!bottomSearchBar" class="fa fa-search input-icon"></label>

    <span class="export-button top" *ngIf="(exportUrl !== '') && totalRecords > 0">
      <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download Excel">
        <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
        XLS
      </span>
    </span>

    <span class="export-button top" *ngIf="(printUrl !== '') && totalRecords > 0">
      <span class="header-action-btn export" (click)="exportGridPdf($event)" title="Download Pdf">
        <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>
        PDF
      </span>
    </span>

    <span class="export-button top" *ngIf="(exportCSVUrl !== '') && totalRecords > 0">
      <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download csv">
        <i class="fa fa-fw fa-file-o" aria-hidden="true"></i>
        CSV
      </span>
    </span>
  </div>

  <div class="ui-g-12">
    <p-table #dt [ngClass]="isDetailScreen ? 'detail-screen-available' : ''" [lazy]="true" [rows]="paginationPageSize"
      metaKeySelection="false" [value]="datasource" [paginator]="pagination" [totalRecords]="totalRecords"
      (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection" (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords" [resizableColumns]="enableResizer"
      [first]="first">

      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of columnDefs">
            <th *ngIf="col.resize && enableResizer" [ngClass]="col?.class ? col.class : ''" pResizableColumn
              [pSortableColumn]="(col?.sort) ? col.sortId : ''" [width]="col?.width" title="{{col.header}}">
              <span *ngIf="col?.header !=='Actions'">{{col.header}}</span>
              <span *ngIf="col?.header =='Actions'" class="pl-10">{{col.header}}</span>
              <p-sortIcon *ngIf="col?.sort" [field]="col.sortId"></p-sortIcon>
            </th>

            <th *ngIf="!col.resize" [ngClass]="col?.class ? col.class : ''"
              [pSortableColumn]="(col?.sort) ? col.sortId : ''" title="{{col.header}}">
              <span *ngIf="col?.header !=='Actions'">{{col.header}}</span>
              <span *ngIf="col?.header =='Actions'" class="pl-10">{{col.header}}</span>
              <p-sortIcon *ngIf="col?.sort" [field]="col.sortId"></p-sortIcon>
            </th>
          </ng-container>

          <th class="action-column" *ngIf="actionButtonsDefs.length" title="Action" [width]="actionWidth" style="text-align: center;">
            Action
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td *ngIf="actionButtonsDefs.length === 0" class="action-header" [attr.colspan]="columnDefs.length">
            <span class="action-text">
              No records available to show at this moment.
            </span>
          </td>
          <td *ngIf="actionButtonsDefs.length > 0" class="action-header" [attr.colspan]="columnDefs.length + 1">
            <span class="action-text">
              No records available to show at this moment.
            </span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [ngClass]="[getDropdownColor(rowData)]">
          <td [ngClass]="col?.class ? col.class : ''" *ngFor="let col of columnDefs">
            <div *ngIf=" !col?.isDropDown && col.cellRendererHtml == undefined" [title]="rowData[col.field] !== null ? rowData[col.field] : ''">
              {{rowData[col.field] ? rowData[col.field]:'--'}} </div>
            <div *ngIf="col.cellRendererHtml != undefined" [title]="getTitle(rowData[col.field].innerHTML)"   [innerHtml]="rowData[col.field].innerHTML | keepHtml">
            </div>
            <div *ngIf="col?.isDropDown" class="grid-dropdown-section filter-drop-down width-100per">
              <p-dropdown [options]="col.options" appendTo="body" [placeholder]="'label_select' | translate"
                [style]="{'width':'100%'}" data-action-type="DROP-DOWN">
              </p-dropdown>
              <br />
            </div>
          </td>

          <td class="grid-action-buttons" *ngIf="actionButtonsDefs.length">
            <div class="grid-action">
              <span *ngFor="let btn of actionButtonsDefs" [ngClass]="rowData[btn.field] ? 'show' : 'hide-grid-action'">
                <div class="render-html" innerHTML="{{rowData[btn.field]}}"
                  (click)="actionBtnClick($event, rowData, btn.class)"></div>
              </span>
            </div>
          </td>

          <!-- <td [ngClass]="col?.class ? col.class : ''" *ngIf="actionButtonsDefs.length">
            <span *ngFor="let btn of actionButtonsDefs">
              <span *ngIf="rowData[btn.field] !== 'deactivated'">
                <button *ngIf="btn.conditionValue === rowData[btn.key]" (click)="actionBtnClick(rowData, btn.class)"
                  [icon]="btn.icon" [title]="btn?.label" [label]="btn?.label" [class]="btn.class" pButton
                  appRipple></button>
              </span>
            </span>
          </td> -->
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>