import { Injectable } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { AppSettings } from '../app.settings';
import { CommonBindingDataService } from './common-binding-data.service';
import { UiService } from './ui.service';
import { SidebarService } from './sidebar.service';
import { ErrorBindingService } from './error-binding.service';
import { MessagingService } from './messaging.service';
declare var Stripe: any;

@Injectable()
export class UserCommonService {
  isMetadataLoaded = false;
  isCompanyRole = false;
  stripElements: any;
  stripe: any;
  userEmail: string;
  otpId: any;
  loginErrors = [{
    email: false,
    password: false,
  }];
  isMeCallSuccess = false;
  isUserMetadataPresent = false;
  isRolesAndGenerateSidebar = false;
  userInfo;
  logginuserId: any;
  constructor(
    private restApiService: RestApiService,
    private uiService: UiService,
    private commonService: CommonBindingDataService,
    private sidebarService: SidebarService,
    private errorBindingService: ErrorBindingService,
    private storageService: StorageService,
    private messagingService: MessagingService) {
    this.messagingService.requestPermission();
  }

  setOtpValue(value) {
    this.otpId = value;
  }

  signOut(): Observable<any> {
    return this.restApiService.delete('sign-out', '/secure/signout', 'page-center');
  }

  signOutSSO(token: any): Observable<any> {
    return this.restApiService.get('public', `/saml2/ssologout?logoutToken=${token}`, 'page-center');
  }

  signIn(data): Observable<any> {
    return this.restApiService.post('sign-in', '/user-signin', data, 'page-center', true);
  }

  signInSSO(): Observable<any> {
    return this.restApiService.get('public', '/saml2/ssologin', 'page-center',);
  }

  UserSignInSSO(data): Observable<any> {
    return this.restApiService.post('public', '/saml2/authcreation', data, 'page-center', true);
  }

  passwordChange(data): Observable<any> {
    return this.restApiService.put('', '/secure/users/profile/password', data, 'page-center');
  }

  setPassword(data): Observable<any> {
    return this.restApiService.post('public', '/users/set-password', data, 'page-center');
  }

  setPasswordFirtTime(data, token): Observable<any> {
    return this.restApiService.put('Set Password', `/users/signup/changes-password/${token}`, data, 'page-center');
  }

  sendOtp(token): Observable<any> {
    return this.restApiService.get('Send OTP', `/users/signup-otp/genetrate/${token}`, 'page-center');
  }

  updateFCMNotificationToken(data): Observable<any> {
    return this.restApiService.post('', `/secure/device`, data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('public', '/secure/users/profile', 'page-center');
  }

  updateUserProfile(data): Observable<any> {
    return this.restApiService.put('', '/secure/users/user/profile', data, 'page-center');
  }

  forgotPassword(data): Observable<any> {
    this.userEmail = data.email;
    return this.restApiService.post('public', '/users/forgot-password', data, 'page-center');
  }

  userDeletedNotify(data): Observable<any> {
    this.userEmail = data.email;
    return this.restApiService.get('public', '/user/' + this.userEmail + '/is-deleted', 'page-center');
  }


  validateOtp(data): Observable<any> {
    return this.restApiService.post('public', '/users/verify-otp', data, 'page-center');
  }

  getServiceAreaId(): Observable<any> {
    return this.restApiService.get('', '/secure/tenants/servicearea', 'page-center');
  }

  getUserMetaData(): Observable<any> {
    return this.restApiService.get('', '/secure/user-metadata', 'page-center');
  }

  getTagsListApi(): Observable<any> {
    return this.restApiService.get('', '/secure/tags/list', 'page-center');
  }

  getUserMetaDataInsecure(): Observable<any> {
    return this.restApiService.get('meta-data', '/admin/metadata', 'page-center');
  }

  getTrackMyLocationDetails(bookingId): Observable<any> {
    return this.restApiService.get('public', '/track-my-location/' + bookingId, 'page-center');
  }

  getImage(fileId): Observable<any> {
    return this.restApiService.base64Image('public', '/file/' + fileId, 'page-center');
  }

  getTrackMyLiveLocation(bookingId): Observable<any> {
    return this.restApiService.get('public', '/track-my-location/live/' + bookingId, 'page-center');
  }

  userMe(): Observable<any> {
    return this.restApiService.get('', '/secure/users/me', 'page-center');
  }

  sesionRefresh(): Observable<any> {
    return this.restApiService.get('', '/secure/users/refresh', 'page-center');
  }

  getQuestionsData(type): Observable<any> {
    return this.restApiService.get('', `/sdoh-screening-form?formType=${type}`, 'page-center');
  }

  isSDoHFormSubmitted(id): Observable<any> {
    return this.restApiService.get('public', '/sdoh-screening-form/' + id + '/validate', 'page-center');
  }

  submitPatientSDoHForm(data): Observable<any> {
    return this.restApiService.post('', `/sdoh-screening-form`, data, 'page-center');
  }

  getPrivacyPolicy(): Observable<any> {
    return this.restApiService.get('', '/tenants/privacy-policy', 'page-center');
  }

  getReportAuthentication(exportId, loggedInUserId): Observable<any> {
    return this.restApiService.get('', '/v1/secure/file-export/' + exportId + '?userId=' + loggedInUserId, 'page-center');
  }

  getAdminUserMetaData() {
    // this.getUserMetaDataInsecure().subscribe(result => {
    this.getUserMetaData().subscribe(result => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${result.googleBrowserAPIKey}&libraries=visualization,places&callback=Function.prototype`;
      document.getElementsByTagName('head')[0].appendChild(script);
      setTimeout(() => {
        this.isMetadataLoaded = true;
      }, 500);
    }, (error) => {
    });
  }

  getUserMasterMetaData() {
    return new Promise((resolve, reject) => {
      this.getUserMetaData().subscribe(result => {
        this.isUserMetadataPresent = true;
        this.storageService.setItem(AppSettings.TIME_SETTINGS, result.timeSettingsList);
        this.storageService.setItem(AppSettings.TENANT_CONFIG, result.tenantConfigKeys);
        this.storageService.setItem(AppSettings.TENANT_INFO, result.tenantInfo);
        this.storageService.setItem(AppSettings.MEMBER_REQUIREMENT, result.memberRequirementList);
        this.storageService.setItem(AppSettings.WEEK_DAY, result.dayOfWeekList);
        this.storageService.setItem(AppSettings.PROVIDER_TYPE, result.providerType);
        this.storageService.setItem(AppSettings.PROVIDER_DASHBOARD, result.providerTypeForDashboard);
        // TODO added RABBITMQ_ENABLED
        this.storageService.setItem(AppSettings.RABBITMQ_ENABLED, result.rabbitMqCcxEnabled);
        this.storageService.setItem(AppSettings.SELECTED_SERVICE_REGION, result.userCitiesList);

        this.storageService.setItem(AppSettings.USER_CITIES_LIST, result.userCitiesList);
        this.storageService.setItem(AppSettings.USER_DISPATCH_TYPE_LIST, result.userDispatchTypeList);
        this.setServiceTypes(result.tenantServiceTypeList);
        this.setServiceArea(result.tenantServiceAreaList);
        // this.setBookingStatus(result.tourStatusConstants);
        this.setEmergencyStatusList(result.emergencyStatusList);
        // this.setAppointmentType(result.appointmentTypeList);
        this.setDriverStatusFilter(result.driverStatusFilter);
        // USERMANAGEMENT CHANGES START//
        // this.setAllServiceRegions();
        // USERMANAGEMENT CHANGES END//
        this.isMetadataLoaded = true;
        const idleTime = Number(this.commonService.getTenantConfigValue('idle_session_time_in_mins')) ?
          Number(this.commonService.getTenantConfigValue('idle_session_time_in_mins')) : AppSettings.IDLE_TIME;
        this.storageService.setItem('idleTime', idleTime);
        this.storageService.setLocalStorage('idleTime', idleTime);
        this.updateFCMToken();
        resolve(true);
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.loginErrors);
        resolve(true);
      });
    });
  }

  // web push notification :: notification token :: start
  updateFCMToken() {
    setTimeout(() => {
      const notification_token = this.storageService.getItem('notificationToken');
      const session_token = this.storageService.getItemFromCookies('sessionToken');

      if (session_token) {
        this.userInfo = this.storageService.getItem(AppSettings.USER_DETAILS);
        const ba = ['Chrome', 'Firefox', 'Safari', 'Opera', 'MSIE', 'Trident', 'Edge'];
        let b;
        const ua = navigator.userAgent;
        for (let i = 0; i < ba.length; i++) {
          if (ua.indexOf(ba[i]) > -1) {
            b = ba[i];
            break;
          }
        }
        if (b === 'MSIE' || b === 'Trident' || b === 'Edge') {
          b = 'Internet Explorer';
        }

        const data = {
          userId: this.userInfo.id,
          tenantId: this.userInfo.tenantId,
          deviceName: b,
          deviceType: 'web',
          fcmToken: notification_token,
          sessionId: session_token
        };

        if (notification_token !== '' || notification_token !== null) {
          this.updateFCMNotificationToken(data).subscribe(res => {
            if (res) {
            }
          }, (error) => {
          });
        }


      }
    }, 3000);
  }
  // web push notification :: notification token :: end

  setServiceTypes(serviceTypes) {
    const serviceTypeArray: any[] = [
      { label: this.commonService.getLabel('please_select'), value: '' },
    ];
    for (let type = 0; type < serviceTypes.length; type++) {
      serviceTypeArray.push({ label: serviceTypes[type].serviceType, value: serviceTypes[type].id });
    }
    this.storageService.setItem(AppSettings.SERVICE_TYPE, serviceTypeArray);
  }

  setServiceArea(serviceAreas) {
    const serviceAreaArray: any[] = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    const serviceAreaAliasArray: any[] = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    const serviceAreaMapArray: any[] = [{ label: this.commonService.getLabel('label_all_cities'), value: AppSettings.TENANT }];
    const multiSelectServiceArea: any[] = [];

    for (let area = 0; area < serviceAreas.length; area++) {
      serviceAreaArray.push({ label: serviceAreas[area].cityName, value: serviceAreas[area].id });
      serviceAreaMapArray.push({ label: serviceAreas[area].cityName, value: serviceAreas[area].id });
      serviceAreaAliasArray.push({ label: serviceAreas[area].cityAliasName, value: serviceAreas[area].id });
      multiSelectServiceArea.push({ label: serviceAreas[area].cityAliasName, value: serviceAreas[area].id });
    }
    this.storageService.setItem(AppSettings.SERVICE_AREA, serviceAreaArray);
    this.storageService.setItem(AppSettings.SERVICE_AREA_ALIAS, serviceAreaAliasArray);
    this.storageService.setItem(AppSettings.SERVICE_CITIES_MAP, serviceAreaMapArray);
    this.storageService.setItem(AppSettings.MUTLTI_SELECT_SERVICE_CITIES, multiSelectServiceArea);
  }

  setBookingStatus(bookingStatus) {
    const bookingStatusArray = [{ label: this.commonService.getLabel('label_all'), value: '' }];
    for (let status = 0; status < bookingStatus.length; status++) {
      bookingStatusArray.push({ label: bookingStatus[status].displayValue, value: bookingStatus[status].tourStatusCode });
    }
    this.storageService.setItem('BOOKING_STATUS', bookingStatusArray);
  }

  setEmergencyStatusList(emergencyStatus) {
    const emergencyStatusList = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < emergencyStatus.length; i++) {
      emergencyStatusList.push({ label: emergencyStatus[i].value, value: emergencyStatus[i].id });
    }
    this.storageService.setItem(AppSettings.EMERGENCY_STATUS_LIST, emergencyStatusList);
  }

  setAppointmentType(appointmentStatus) {
    const appointmentArray = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    for (let status = 0; status < appointmentStatus.length; status++) {
      appointmentArray.push({ label: appointmentStatus[status].appointmentType, value: appointmentStatus[status].appointmentTypeId });
    }
    this.storageService.setItem(AppSettings.APPOINTMENT_TYPE_LIST, appointmentArray);
  }

  setColorsList(colors) {
    const colorsList = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < colors.length; i++) {
      colorsList.push({ label: colors[i].color, value: colors[i].id });
    }
    this.storageService.setItem(AppSettings.COLORS_LIST, colorsList);
  }

  setYearsList(years) {
    const yearsList = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < years.length; i++) {
      yearsList.push({ label: years[i].year, value: years[i].id });
    }
    this.storageService.setItem(AppSettings.YEARS_LIST, yearsList);
  }

  setMakeDetailsList(makeDetails) {
    const makeDetailsList = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < makeDetails.length; i++) {
      makeDetailsList.push({ label: makeDetails[i].make, value: makeDetails[i].id });
    }
    this.storageService.setItem(AppSettings.MAKE_DETAILS_LIST, makeDetailsList);
  }

  setDriverStatusFilter(status) {
    const statusList = [{ label: this.commonService.getLabel('please_select'), value: '' }];
    for (let i = 0; i < status.length; i++) {
      statusList.push({ label: status[i].value, value: status[i].id });
    }
    this.storageService.setItem(AppSettings.DRIVER_STATUS_FILTER, statusList);
  }

  setRolesAndGenerateSidebar() {
    const userDetails: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userDetails.roles[0].roleCode !== undefined && userDetails.roles[0].roleCode !== null) {
      this.isRolesAndGenerateSidebar = true;
      AppSettings.LOGGED_IN_ROLE = userDetails.roles[0].roleCode;
      this.sidebarService.generateLeftMenuBar();
      this.uiService.profileUpdated();
    } else {
      this.storageService.removeAll();
      const error = {
        general: [{
          message: this.commonService.getLabel('label_invalid_role')
        }]
      };
      this.errorBindingService.onAPIValidationError(error, this.loginErrors);
    }
  }

  getDispatchListFilter() {
    const type = 'DISPATCH_LIST_GRID';
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.logginuserId = userObj.id;
    }
    this.commonService.dispatchGetFilter(this.logginuserId, type).subscribe(results => {
      if (results.filterData !== null && results.filterData !== undefined) {
        const filterResults = JSON.parse(results.filterData);
        this.storageService.setItem(AppSettings.FILTER_DETAILS, filterResults);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getContactListFilter() {
    const type = 'CONTACT_FORM_LIST_GRID';
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.logginuserId = userObj.id;
    }
    this.commonService.contactGetFilter(this.logginuserId, type).subscribe(results => {
      if (results.filterData !== null && results.filterData !== undefined) {
        const filterResults = JSON.parse(results.filterData);
        this.storageService.setItem(AppSettings.CONTACT_FILTER_DETAILS, filterResults);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getTeamFilter() {
    const type = 'TEAM_FILTER';
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.logginuserId = userObj.id;
    }
    this.commonService.teamGetFilter(this.logginuserId, type).subscribe(results => {
      if (results.filterData !== null && results.filterData !== undefined) {
        const filterResults = JSON.parse(results.filterData);
        this.storageService.setItem(AppSettings.FILTER_DETAILS_TEAM, filterResults);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getTeamMemberFilter() {
    const type = 'TEAM_MEMBER_FILTER';
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.logginuserId = userObj.id;
    }
    this.commonService.teamMemberGetFilter(this.logginuserId, type).subscribe(results => {
      if (results.filterData !== null && results.filterData !== undefined) {
        const filterResults = JSON.parse(results.filterData);
        this.storageService.setItem(AppSettings.FILTER_DETAILS_TEAM_MEMBER, filterResults);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getManageShiftFilter() {
    const type = 'OPEN_SHIFT_FILTER';
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.logginuserId = userObj.id;
    }
    this.commonService.manageShiftGetFilter(this.logginuserId, type).subscribe(results => {
      if (results.filterData !== null && results.filterData !== undefined) {
        const filterResults = JSON.parse(results.filterData);
        this.storageService.setItem(AppSettings.FILTER_DETAILS_MANAGE_SHIFT, filterResults);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  getShiftPlaningFilter() {
    const type = 'SHIFT_PLANNING_FILTER';
    const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userObj !== undefined && userObj !== null) {
      this.logginuserId = userObj.id;
    }
    this.commonService.shiftPlanGetFilter(this.logginuserId, type).subscribe(results => {
      if (results.filterData !== null && results.filterData !== undefined) {
        const filterResults = JSON.parse(results.filterData);
        this.storageService.setItem(AppSettings.FILTER_DETAILS_PANNING, filterResults);
      }
    }, (error) => {
      this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }
  // USERMANAGEMENT CHANGES START//
  // setAllServiceRegions() {
  //   this.commonService.getServiceRegions().subscribe(results => {
  //   this.storageService.setItem(AppSettings.ALL_SERVICE_REGION, results);
  // }, (error) => {
  // });
  // }
  // USERMANAGEMENT CHANGES END//

}

