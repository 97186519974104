<div class="ui-g">
  <form [formGroup]='editPoliciesForm'>
    <div class="ui-g-12 ui-lg-12">
      <p-editor formControlName="policies" [(ngModel)]="privacyPolicyData" [style]="{'height':'320px'}">
        <p-header>
          <span class="ql-format-group">
            <span class="ql-formats">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-script" value="sub"></button>
                <button class="ql-script" value="super"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-header" value="1"></button>
                <button class="ql-header" value="2"></button>
                <button class="ql-blockquote"></button>
                <button class="ql-code-block"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-direction" value="rtl"></button>
                <select class="ql-align"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-link"></button>
              </span>
            </span>
          </span>
        </p-header>
      </p-editor>
    </div>

    <div class="form-buttons ui-g-12 ui-g-nopad pb-20">
      <button class="save-btn" pButton type="button" [label]="'label_save' | translate"
        (click)="addPrivacyPolicies()"></button>
      <button class="cancel-btn" pButton type="button" [label]="'label_cancel' | translate" (click)="Cancel()"></button>
    </div>

  </form>
</div>