import { Component, OnInit, ViewChild } from '@angular/core';
import { Message } from 'primeng/api';

import { UiService } from 'app/modules/shared/services/ui.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EditablePrivacyPolicyComponent } from '../tenant-settings/components/editable-privacy-policy/editable-privacy-policy.component';
import { CommonBindingDataService } from '../shared/services/common-binding-data.service';
import { TenantService } from '../tenant-settings/services/tenant.service';
import { SettingService } from '../settings/services/setting.service';
import { UserCommonService } from '../shared/services/user-common.service';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})

export class privacyPolicyComponent implements OnInit {
  @ViewChild(EditablePrivacyPolicyComponent) editChild: EditablePrivacyPolicyComponent;
  showEditPolicyPopup = false;
  data: any = null;
  labelEdit;

  constructor(
    private commonService: CommonBindingDataService,
    private userCommonService: UserCommonService
  ) { }

  ngOnInit() {
    this.labelEdit = this.commonService.getLabel('label_edit');
    this.bindData();
  }

  bindData() {
    this.userCommonService.getPrivacyPolicy().subscribe(results => {
      this.data = results;
    }, (error) => {
    });
  }

  // editPolicy() {
  //   this.editChild.onbind(this.data);
  //   this.showEditPolicyPopup = true;
  // }

  // onPopCancel(event: any) {
  //   this.showEditPolicyPopup = !this.showEditPolicyPopup;
  //   if (event !== undefined && event !== null) {
  //     this.uiService.showMessage(event.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
  //   }
  // }

  // onPopSave(results) {
  //   this.showEditPolicyPopup = !this.showEditPolicyPopup;
  //   this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
  //   this.bindData();
  // }

}
