import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-activity',
  templateUrl: './dashboard-activity.component.html'
})

export class DashboardActivityComponent {
  @Input() headerLHS;
  @Input() headerRHS;
  @Input() activityNotifications;

  constructor() { }

}
