import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { displayTruncatedText } from 'app/modules/shared/utility-functions/helperFunctions';
import { ManageOpenShiftsService } from '../../services/manage-open-shifts.service';

@Component({
  selector: 'app-my-open-shifts-slot-card',
  templateUrl: './my-open-shifts-slot-card.component.html',
  styleUrls: ['./my-open-shifts-slot-card.component.scss']
})
export class MyOpenShiftsSlotCardComponent implements OnInit {
  @Input() teamDetails: any;
  @Input() openShiftStatusList: any;
  openShiftStatusColors = AppSettings.MY_OPEN_SHIFT_STATUS;
  teamName = '';
  borderClass = '';
  istrim = false;

  constructor(private manageOpenShiftsService: ManageOpenShiftsService) { }

  ngOnInit(): void {
    const fullName = `${this.teamDetails.createdByFirstName} ${this.teamDetails.createdByLastName}`;
    this.teamName = this.displayTruncatedTextValue(fullName);
    this.istrim = this.teamName.split('').length > 15;
    // this.borderClass = this.manageOpenShiftsService.convertToCssClass(this.teamDetails?.applicationStatusStr);
    this.borderClass = this.openShiftStatusColors[this.teamDetails?.applicationStatus] || '';
  }

  displayTruncatedTextValue(value) {
    return displayTruncatedText(value, 15);
  }

}
