<div class="signin-box-container ui-g">
  <div class="signin-box-inner-container">
    <div class="signin-box ui-md-4 ui-md-offset-4">
      <div class="forgot-pass-form">
        <div class="form-field ui-g-12 ui-g-nopad">
          <div class="form-field ui-g-12 ui-g-nopad text-center">
            <img class="company-logo-background" src='./assets/images/temp/rainbowcare_logo.png'>
          </div>
          <div class="form-field ui-g-12 switch-act-verf" translate>Account Verification</div>
          <p style="text-align: center; font-size: large;color: #7a7a7a;font-size: 16px;letter-spacing: 0.08px;"
          >Please enter your registered email to proceed 

          </p>
          <div class="form-field ui-g-12"></div>
        </div>
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
          <form [formGroup]='editforgotForm'>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
              <div class="form-label">
                <label for="username" translate>Username</label>
              </div>
              <div class="form-control-c">
                <input autocomplete="off" formControlName="username" class="bottom-border" type="text"
                  (blur)="validateEmail()" pInputText />
              </div>
              <div class="form-errors"
                *ngIf="editforgotForm.controls.username.errors  && (editforgotForm.controls.username.dirty || editforgotForm.controls.username.touched)">
                <div [hidden]="!editforgotForm.controls.username.errors.required" translate>
                  email_required
                </div>
                <!-- <div [hidden]="!editforgotForm.controls.username.errors.pattern" translate>
                  err_invalid_emailId
                </div> -->
                <div *ngIf="isEmailInvalid" translate>
                  err_invalid_emailId
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12">
              <div class="ui-g-12 ui-g-nopad">
                <button pButton type="button" class="signin-save-btn" label="Verify" [disabled]="!editforgotForm.valid"
                  (click)="changePassword($event)"></button>
                <!-- <button pButton class="cancel-back-btn " type="button" label="Cancel"
                  (click)="onBackSign($event)"></button> -->
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12 back-to-lgn text-center">
            <span style="letter-spacing: 0.86px;" >Back to <a href="">Login</a></span>
            </div>
            <div class="form-field ui-g-12 ui-md-12 ui-lg-12 back-to-lgn text-center">
              <span class="form-errors" *ngIf="isUserDeleted">{{UserDeletederror}}</span>
              <span class="uase-acctive" *ngIf="isUserActivate">{{UserDeletederror}}</span>


              </div>
          </form>
        </div>

        <div class="remove-float"></div>
      </div>

    </div>
  </div>
</div>
