import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { Router } from '@angular/router';
import { AppSettings } from '../../../shared/app.settings';
import { UserCommonService } from './../../services/user-common.service';
import { ErrorBindingService } from '../../services/error-binding.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-user-account-deleted',
  templateUrl: './user-account-deleted.component.html',
})

export class UserAccountDeletedComponent implements OnInit {
  @Output() popClose: EventEmitter<any> = new EventEmitter();
  @Output() popSave: EventEmitter<any> = new EventEmitter();
  isEmailInvalid: Boolean;

  forgotPasswordErrors = [
    {
      username: false
    }
  ];
  editforgotForm = new FormGroup({
    username: new FormControl()
  });
  isUserDeleted: any = false;
  isUserActivate: any = false;
  UserDeletederror: any;
  constructor(
    private formBuilder: FormBuilder,
    private commonServices: UserCommonService,
    private errorBindingService: ErrorBindingService,
    private uiService: UiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isEmailInvalid = false;
    this.editforgotForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(AppSettings.EMAIL_PATTERN)]],
    });
  }

  closeEditForgot(event) {
    this.editforgotForm.reset();
    this.popClose.emit();
  }

  onBackSign(event) {
    this.editforgotForm.reset();
    this.router.navigate(['signin']);
  }

  changePassword(event) {
    let input;
    let otpEmail;
    let otpPhone;
    const userName = this.editforgotForm.controls.username.value.trim();
    if (userName.match(AppSettings.EMAIL_PATTERN)) {
      otpEmail = userName;
      input = {
        email: userName,
        tenantId: AppSettings.TENANT
      };
    } else if (userName.match(AppSettings.PHONE_PATTERN)) {
      otpPhone = userName;
      input = {
        phoneNum: userName,
        tenantId: AppSettings.TENANT
      };
    } else {
      input = {
        username: userName,
        tenantId: AppSettings.TENANT
      };
    }

    this.commonServices.userDeletedNotify(input).subscribe(results => {
      this.UserDeletederror = results.general[0].message;
      this.isUserDeleted = results.isDeleted;
      this.isUserActivate = results.isActivate;
      // this.uiService.showMessage(results.general[0].message, AppSettings.MESSAGE_TYPES.SUCCESS);
    }, (error) => {
      // this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPES.ERROR);
    });
  }

  validateEmail() {
    this.isEmailInvalid = false;
    if (this.editforgotForm.get('username').value) {
      if (!AppSettings.EMAIL_PATTERN.test(this.editforgotForm.get('username').value)) {
        this.isEmailInvalid = true;
      }
    }
  }

}
