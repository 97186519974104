import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppSettings } from '../app.settings';
@Pipe({
  name: 'datetimeformat'
})
export class DateTimeFormatPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    return moment(value).format(AppSettings.DATE_TIME_FORMATTER);
  }

}
