import { AppSettings } from './../app.settings';
import * as moment from 'moment';

export function formatDateCell(timestamp) {
  return moment(timestamp).format(AppSettings.DATE_TIME_FORMATTER);
}

export function formatDateCellInDateOnly(timestamp) {
  return moment(timestamp).format(AppSettings.DATE_FORMATTER);
}

export function toLocalDateAndtime(timeStamp) {
  const now = new Date(timeStamp);
  const localTime = new Date(timeStamp + now.getTimezoneOffset() * 60000);
  return moment(localTime).format(AppSettings.DATE_TIME_FORMATTER);
}

export function formatDateTimeCell(timestamp) {
  const date = moment(timestamp).format(AppSettings.DATE_FORMATTER);
  const time = moment(timestamp).format(AppSettings.TIME_FORMATTER);
  return date + ' ' + time;
}
