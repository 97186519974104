import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { NgModule, ModuleWithProviders, isDevMode } from '@angular/core';
import { SignInLayoutComponent } from './pages/sign-in-layout/sign-in-layout.component';
import { AccordionModule, InputTextModule } from 'primeng';
import { StorageService } from './modules/shared/services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderService } from './modules/shared/components/loader/loader.service';
import { EditorModule } from 'primeng';
import { PanelModule } from 'primeng';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng';
import { ButtonModule } from 'primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng';
import { CalendarModule } from 'primeng';
import { FieldsetModule } from 'primeng';
import { FileUploadModule } from 'primeng';
import { MultiSelectModule } from 'primeng';
import { OverlayPanelModule } from 'primeng';
import { ConfirmDialogModule } from 'primeng';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { TrackMyLocationComponent } from './pages/track-my-location/track-my-location.component';
import { LoginComponent } from './pages/login/login.component';
import { PasswordModule } from 'primeng';
import { MessageService } from 'primeng';
import { ComplaintComponent } from './pages/complaint/complaint.component';
import { DropdownModule } from 'primeng';
import { CheckboxModule } from 'primeng';
import { RadioButtonModule } from 'primeng';
import { SidebarModule } from 'primeng';
import { PasswordSetWithOtpVerifyComponent } from './pages/password-set-with-otp-verify/password-set-with-otp-verify.component';
import { RedirectionPageComponent } from './pages/redirection-page/redirection-page.component';
import { SupportComponent } from './pages/support/support.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment.dev';
import { MessagingService } from './modules/shared/services/messaging.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SdohPatientFormComponent } from './pages/sdoh-patient-form/sdoh-patient-form.component';
import { TwilioVideoComponent } from './pages/twilio-video/twilio-video.component';
import { SignInPageComponent } from './pages/sign-in-page/sign-in-page.component';
import { SsosignInPageComponent } from './pages/sso-sign-in-page/ssosign-in-page.component';
import { EditableContactFormComponent } from './modules/contact-form/components/editable-contact-form/editable-contact-form.component';
import { privacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { SignInErrorComponent } from './pages/sign-in-error/sign-in-error.component';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { WhiteSpaceTrimDirective } from './modules/shared/directives/white-space-trim.directive';
import { ReportDownloadAuthorizationComponent } from './pages/report-download-authorization/report-download-authorization.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeploymentRefreshService } from './modules/shared/services/deployment-refresh.service';

@NgModule({
  declarations: [
    AppComponent,
    SignInLayoutComponent,
    TrackMyLocationComponent,
    LoginComponent,
    ComplaintComponent,
    PasswordSetWithOtpVerifyComponent,
    RedirectionPageComponent,
    SupportComponent,
    SdohPatientFormComponent,
    TwilioVideoComponent,
    SignInPageComponent,
    SsosignInPageComponent,
    privacyPolicyComponent,
    SignInErrorComponent,
    privacyPolicyComponent,
    WhiteSpaceTrimDirective,
    ReportDownloadAuthorizationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    ButtonModule,
    ToastModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    PasswordModule,
    SharedModule.forRoot(),
    DropdownModule,
    CheckboxModule,
    RadioButtonModule,
    HammerModule,
    SidebarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: true,
    // enabled: !isDevMode(),
    // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [
    StorageService,
    ConfirmationService,
    LoaderService,
    NotificationsService,
    MessageService,
    ToastModule,
    MessagingService,
    AsyncPipe,
    // DeviceDetectorService
    DeploymentRefreshService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule
    };
  }
  constructor() {
  }
}
